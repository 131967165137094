@if (show && !isPageOnly) {
<mat-nav-list>
  <mat-expansion-panel [class.mat-elevation-z0]="true" [expanded]="expandPanel">
    <mat-expansion-panel-header
      class="headerPanel"
      [collapsedHeight]="'48px'"
      [expandedHeight]="'48px'"
    >
      CRUX DATA
    </mat-expansion-panel-header>
    <mat-list-item
      routerLink="{{ routeHome }}{{ routes.OVERVIEW }}"
      routerLinkActive="active"
    >
      <div class="px-2 d-flex justify-content-start align-items-center">
        <mat-icon>analytics</mat-icon>
        <span> Domain Overview</span>
      </div>
    </mat-list-item>
    <mat-list-item
      routerLink="{{ routeHome }}/insights"
      routerLinkActive="active"
    >
      <div class="px-2 d-flex justify-content-start align-items-center">
        <mat-icon>psychology</mat-icon>
        <span>Perf Analysis</span>
      </div>
    </mat-list-item>
    <mat-list-item (click)="openUiElements()">
      <div class="px-2 d-flex justify-content-start align-items-center">
        <mat-icon>timeline</mat-icon>
        <span>Metrics over time</span>
      </div>
    </mat-list-item>
    <mat-list-item
      class="ui-element"
      routerLink="{{ routeHome }}{{ routes.TTFB }}"
      routerLinkActive="active"
    >
      <div class="px-2 d-flex justify-content-start align-items-center">

        <span class="mx-3">TTFB</span>
      </div>
    </mat-list-item>

    <mat-list-item
      class="ui-element"
      routerLink="{{ routeHome }}{{ routes.FCP }}"
      routerLinkActive="active"
    >
      <div class="px-2 d-flex justify-content-start align-items-center">

        <span class="mx-3">FCP</span>
      </div>
    </mat-list-item>

    <mat-list-item
      class="ui-element"
      routerLink="{{ routeHome }}{{ routes.LCP }}"
      routerLinkActive="active"
    >
      <div class="px-2 d-flex justify-content-start align-items-center">

        <span class="mx-3">LCP</span>
      </div>
    </mat-list-item>

    <mat-list-item
    class="ui-element"
    routerLink="{{ routeHome }}{{ routes.INP }}"
    routerLinkActive="active"
  >
    <div class="px-2 d-flex justify-content-start align-items-center">

      <span class="mx-3">INP<mat-icon style="color: #ef6f52">whatshot</mat-icon></span>
    </div>
  </mat-list-item>

    <mat-list-item
      class="ui-element"
      routerLink="{{ routeHome }}{{ routes.CLS }}"
      routerLinkActive="active"
    >
      <div class="px-2 d-flex justify-content-start align-items-center">

        <span class="mx-3">CLS</span>
      </div>
    </mat-list-item>



    <mat-list-item
    class="ui-element"
    routerLink="{{ routeHome }}{{ routes.NAV_TYPE}}"
    routerLinkActive="active"
  >
    <div class="px-2 d-flex justify-content-start align-items-center">
      <span class="mx-3">Nav types</span>
    </div>
  </mat-list-item>

  <mat-list-item id="CRUX_WORKFLOW"routerLink="{{ routeHome }}{{ routes.CRUX_WORKFLOW }}"routerLinkActive="active">
    <div class="px-2 d-flex justify-content-start align-items-center">
      <mat-icon>card_membership</mat-icon>
      <span style="overflow: hidden; word-wrap: break-word">
        User Journeys
      </span>
    </div>
  </mat-list-item>

    <mat-list-item
      id="INTERNAL_PAGES"
      routerLink="{{ routeHome }}{{ routes.INTERNALPAGES }}"
      routerLinkActive="active"
    >
      <div class="px-2 d-flex justify-content-start align-items-center">
        <mat-icon>filter_none</mat-icon>
        <span>Internal Pages</span>
      </div>
    </mat-list-item>

    <mat-list-item id="COMPETITIVE"routerLink="{{ routeHome }}{{ routes.COMPARISON }}"routerLinkActive="active">
      <div class="px-2 d-flex justify-content-start align-items-center">
        <mat-icon>flag</mat-icon>
        <span style="overflow: hidden; word-wrap: break-word">
          Competitive benchmark
        </span>
      </div>
    </mat-list-item>
    <mat-list-item id="OFFER"routerLink="{{ routeHome }}{{ routes.OFFER }}"routerLinkActive="active">
      <div class="px-2 d-flex justify-content-start align-items-center">
        <mat-icon>local_offer</mat-icon>
        <span style="overflow: hidden; word-wrap: break-word">
          Help Requests
        </span>
      </div>
    </mat-list-item>
  </mat-expansion-panel>
</mat-nav-list>
} @if (showFirstPartyDetailSidebar && projectHasFPD) {
<mat-nav-list>
  <mat-expansion-panel
    [class.mat-elevation-z0]="true"
    [expanded]="!expandPanel"
  >
    <mat-expansion-panel-header
      class="headerPanel"
      [collapsedHeight]="'48px'"
      [expandedHeight]="'48px'"
    >
      RUM DATA
    </mat-expansion-panel-header>
    <mat-list-item
      routerLink="{{ routeHomePathFirstParty }}{{ routes.OVERVIEW }}"
      routerLinkActive="active"
    >
      <div class="px-2 d-flex justify-content-start align-items-center">
        <mat-icon>analytics</mat-icon>
        <a style="overflow: hidden; word-wrap: break-word">Domain Overview</a>
      </div>
    </mat-list-item>
    <mat-list-item (click)="openUiElements()">
      <div class="px-2 d-flex justify-content-start align-items-center">
        <mat-icon>timeline</mat-icon
        ><span style="overflow: hidden; word-wrap: break-word"
          >Metrics over time</span
        >
      </div>
    </mat-list-item>
    <mat-list-item
    class="ui-element"
    routerLink="{{ routeHomePathFirstParty }}{{ routes.TTFB }}"
    routerLinkActive="active"
  >
    <div class="px-2 d-flex justify-content-start align-items-center">

      <span class="mx-3">TTFB</span>
    </div>
  </mat-list-item>
    <mat-list-item
    class="ui-element"
    routerLink="{{ routeHomePathFirstParty }}{{ routes.FCP }}"
    routerLinkActive="active"
  >
    <div class="px-2 d-flex justify-content-start align-items-center">

      <span class="mx-3">FCP</span>
    </div>
  </mat-list-item>
    <mat-list-item
      class="ui-element"
      routerLink="{{ routeHomePathFirstParty }}{{ routes.LCP }}"
      routerLinkActive="active"
    >
      <div class="px-2 d-flex justify-content-start align-items-center">

        <span class="mx-3">LCP</span>
      </div>
    </mat-list-item>
    <mat-list-item
    class="ui-element"
    routerLink="{{ routeHomePathFirstParty }}{{ routes.INP }}"
    routerLinkActive="active"
  >
    <div class="px-2 d-flex justify-content-start align-items-center">

      <span class="mx-3">INP</span>
    </div>
  </mat-list-item>

    <mat-list-item
      class="ui-element"
      routerLink="{{ routeHomePathFirstParty }}{{ routes.CLS }}"
      routerLinkActive="active"
    >
      <div class="px-2 d-flex justify-content-start align-items-center">

        <span class="mx-3">CLS</span>
      </div>
    </mat-list-item>

    <mat-list-item
      routerLink="{{ routeHomePathFirstParty }}{{ routes.RUM_WORKFLOW }}"
      routerLinkActive="active"
    >
      <div class="px-2 d-flex justify-content-start align-items-center">
        <mat-icon>card_membership</mat-icon>
        <span>User Journeys</span>
      </div>
    </mat-list-item>


    <mat-list-item
      routerLink="{{ routeHomePathFirstParty }}{{ routes.INTERNALPAGES }}"
      routerLinkActive="active"
    >
      <div class="px-2 d-flex justify-content-start align-items-center">
        <mat-icon>filter_none</mat-icon>
        <span>Internal Pages</span>
      </div>
    </mat-list-item>

    <mat-list-item id="OFFER"routerLink="{{ routeHome }}{{ routes.OFFER }}"routerLinkActive="active">
      <div class="px-2 d-flex justify-content-start align-items-center">
        <mat-icon>local_offer</mat-icon>
        <span style="overflow: hidden; word-wrap: break-word">
          Offer
        </span>
      </div>
    </mat-list-item>
  </mat-expansion-panel>
</mat-nav-list>
}
