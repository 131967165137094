import { CommonModule } from '@angular/common';
import { Component, OnInit, ViewChild, AfterViewInit, OnDestroy } from '@angular/core';
import { NavigationWorkflowService } from 'src/app/services/navigation-workflow.service';
import { SpeetalsDatabaseService } from 'src/app/services/common/speetalsDatabase/speetals-database.service';
import { MatSelectModule } from '@angular/material/select';
import { FormsModule } from '@angular/forms';
import { NgApexchartsModule, ChartComponent, ApexAxisChartSeries, ApexChart, ApexPlotOptions, ApexXAxis, ApexDataLabels, ApexLegend, ApexTitleSubtitle } from 'ng-apexcharts';
import { forkJoin, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { MatIconModule } from '@angular/material/icon';
import { MatRadioModule } from '@angular/material/radio';
import * as am5 from "@amcharts/amcharts5";
import * as am5percent from "@amcharts/amcharts5/percent";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import { ShowDetailsWorkflowComponent } from '../show-details-workflow/show-details-workflow.component';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';

export type ChartOptions = {
  series: ApexAxisChartSeries;
  chart: ApexChart;
  plotOptions: ApexPlotOptions;
  dataLabels: ApexDataLabels;
  xaxis: ApexXAxis;
  colors: string[];
  legend: ApexLegend;
  title: ApexTitleSubtitle;
};

interface CruxPageData {
  url: string;
  p75Lcp?: number;
  p75Cls?: number;
  p75Inp?: number;
  uxr?: number;
  updateAt?: Date;
  device?: string;
  error?: string;
}

@Component({
    selector: 'app-crux-workflow',
    standalone: true,
    imports: [CommonModule, NgApexchartsModule, MatSelectModule, FormsModule, MatIconModule, MatRadioModule],
    templateUrl: './crux-workflow.component.html',
    styleUrls: ['./crux-workflow.component.scss']
})
export class CruxWorkflowComponent implements OnInit, AfterViewInit, OnDestroy {
  userId: string;
  workflows: any[] = [];
  selectedWorkflow: any;
  cruxData: CruxPageData[] = [];


  validCruxData: CruxPageData[] = [];

  loading = false;
  errorMsg = '';
  chartErrorMsg = '';
  selectedDevice: string = 'desktop';
  device: string = 'desktop';

  private root!: am5.Root;


  @ViewChild('chart') chart: ChartComponent;
  public chartOptions: Partial<ChartOptions>;
  projectId: string;

  constructor(
    private workflowService: NavigationWorkflowService,
    private speetalsDatabaseService: SpeetalsDatabaseService,
    private dialog: MatDialog,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.projectId = this.router.url.split('/')?.[3];
    this.userId = localStorage.getItem('userId') || '';
    this.loadCruxWorkflows();
  }

  ngAfterViewInit(): void {
    // On peut appeler la méthode de construction du chart si les données sont déjà disponibles.
    if (!this.loading) {
      this.buildAmChartFunnel();
    }
  }



  loadCruxWorkflows(): void {
    if (!this.userId) {
      this.errorMsg = 'No userId found in localStorage.';
      return;
    }
    this.loading = true;
    this.workflowService.getNavigationWorkflows(this.projectId).subscribe({
      next: (wfs) => {
        this.workflows = wfs.filter(wf => wf.type === 'CRUX');
        if (this.workflows.length === 0) {
          this.errorMsg = 'No CRUX workflows found.';
        } else {
          this.selectedWorkflow = this.workflows[0];
          this.loadCruxDataForWorkflow(this.selectedWorkflow);
        }
        this.loading = false;
      },
      error: (err) => {
        console.error(err);
        this.errorMsg = 'Error loading CRUX workflows.';
        this.loading = false;
      },
    });
  }

  selectWorkflow(wf: any): void {
    this.selectedWorkflow = wf;
    this.loadCruxDataForWorkflow(wf);
  }

  loadCruxDataForWorkflow(workflow: any): void {
    if (!workflow || !workflow.pages) return;
    const pages = workflow.pages; // Array of objects { url: '...' }

    const observables = pages.map((p: { url: string }) =>
      this.speetalsDatabaseService.getMostRecentDocByUrlAndUser(this.projectId, p.url).pipe(
        catchError(err => {
          console.error('Error fetching docs for url=', p.url, err);
          return of({ desktop: { url: p.url, error: 'Error fetching CRUX data' }, mobile: { url: p.url, error: 'Error fetching CRUX data' } });
        }),
        map((result: any) => {
          return {
            url: p.url,
            desktop: result.desktop,
            mobile: result.mobile
          };
        })
      )
    );

    forkJoin(observables).subscribe((results: any[]) => {
      this.cruxData = results;
      this.validCruxData = this.cruxData.filter(item => !item[this.selectedDevice]?.error);

      const invalidUrls = this.cruxData.filter(item => item[this.selectedDevice]?.error).map(item => item.url);
      if (invalidUrls.length > 0) {
        this.chartErrorMsg = 'The following URLs have no CRUX data for ' + this.selectedDevice + ': ' + invalidUrls.join(', ');
      } else {
        this.chartErrorMsg = '';
      }
      this.buildAmChartFunnel();
      this.loading = false;
    });
  }

  setDeviceUrl(event: Event): void {
    const target = event.target as HTMLInputElement;
    const deviceValue = target.value;
    this.device = deviceValue;
    this.selectedDevice = deviceValue;
    this.buildAmChartFunnel();
  }

  buildAmChartFunnel(): void {
    if (this.root) {
      this.root.dispose();
    }

    this.root = am5.Root.new("chartdiv");
    this.root.setThemes([am5themes_Animated.new(this.root)]);

    const chart = this.root.container.children.push(
      am5percent.SlicedChart.new(this.root, {
        layout: this.root.verticalLayout,
        interactive: true,
      })
    );

    const chartData = this.cruxData.map(d => ({
      category: d.url,
      value: d[this.selectedDevice]?.uxr || 0
    }));

    const series = chart.series.push(
      am5percent.FunnelSeries.new(this.root, {
        valueField: "value",
        categoryField: "category",
        orientation: "horizontal",
        alignLabels: false,
        bottomRatio: 1,
        interactive: true,
      })
    );
    series.data.setAll(chartData);

    series.slices.template.setAll({
      interactive: true,
      cursorOverStyle: "pointer",
      tooltipText: "{category}: {value}%"
    });

    series.labels.template.setAll({
      text: "UXR: {value}%",
      interactive: false,
      rotation: 0,
      textAlign: "start"
    });

    series.events.on("datavalidated", () => {
      series.dataItems.forEach((dataItem) => {
        const slice = dataItem.get("slice");
        if (slice) {
          slice.setAll({
            interactive: true,
            cursorOverStyle: "pointer"
          });
          slice.events.on("click", () => {
            const dataContext = dataItem.dataContext as { category: string; value: number };
            this.openModal(dataContext.category);
          });
        }
      });
    });




    const legend = chart.children.push(
      am5.Legend.new(this.root, {
        centerX: am5.p50,
        x: am5.p50,
        marginTop: 15,
        marginBottom: 15
      })
    );
    legend.labels.template.set("text", "{category}");
    legend.valueLabels.template.setAll({ forceHidden: true });
    legend.data.setAll(series.dataItems);

    series.appear();
    chart.appear(1000, 100);
  }


  openModal(url: string): void {
    const item = this.cruxData.find(d => d.url === url);
    if (!item) {
      return;
    }

    const deviceData = item[this.selectedDevice];
    if (!deviceData || deviceData.error) {
      return;
    }

    const lcpData = {
      good: deviceData.goodLcp,
      need: deviceData.needsImprovementLcp,
      poor: deviceData.poorLcp,
      p75: deviceData.p75Lcp
    };
    const clsData = {
      good: deviceData.goodCls,
      need: deviceData.needsImprovementCls,
      poor: deviceData.poorCls,
      p75: deviceData.p75Cls
    };
    const inpData = {
      good: deviceData.goodInp,
      need: deviceData.needsImprovementInp,
      poor: deviceData.poorInp,
      p75: deviceData.p75Inp
    };

    const modalData = {
      url: deviceData.url,
      device: deviceData.device,
      lcpData,
      clsData,
      inpData
    };

    this.dialog.open(ShowDetailsWorkflowComponent, {
      width: '1000px',
      height: '500px',
      data: modalData
    });
  }


  ngOnDestroy(): void {
    // Détruire la racine du chart pour éviter les fuites
    if (this.root) {
      this.root.dispose();
    }
  }

  navigateToMyAccount() {
    this.router.navigate(['/home/myAccount'], { queryParams: { tab: 3 } });
  }
}
