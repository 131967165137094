import { Component, OnInit } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { CompilerService } from 'src/app/shared/compiler/compiler.service';
import { ConstantService } from 'src/app/shared/constant/constant.service';
import { HelperService } from 'src/app/shared/helper/helper.service';
import { AuthService } from '../../../../services/core/auth/auth.service';

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss'],
    standalone: false
})
export class LoginComponent implements OnInit {
  loading: boolean = false;
  loginUserFormGroup: UntypedFormGroup;
  modifiedData: any;
  response: any;
  loginData: any;

  constructor(
    private helperService: HelperService,
    private formBuilder: UntypedFormBuilder,
    private compilerService: CompilerService,
    private authService: AuthService,
    private title: Title,
    private meta: Meta,
    private router: Router
  ) {
  }

  ngOnInit() {
    this.title.setTitle('Speetals - Web Performance Vitals');
    this.meta.addTags([
      { name: 'robots', content: 'noindex, nofollow' },
      {
        name: 'description',
        content: 'Monitor your website performance with Speetals.com',
      },
    ]);

    this.loginUserFormGroup = this.formBuilder.group({
      username: ['', [Validators.required]],
      password: [
        '',
        [
          Validators.required,
          Validators.minLength(8),
          Validators.maxLength(30),
        ],
      ],
    });
    const iframeEle: any = document.getElementById("testimonialto-speetals-tag-all-light-animated")
    const loadingEle = document.getElementById("loading")
    iframeEle.style.display = 'none'
    iframeEle.addEventListener('load', function () {
      // Hide the loading indicator
      loadingEle.style.display = 'none';
      iframeEle.style.display = 'inherit'

      // Bring the iframe back
      iframeEle.style.opacity = 1;
  });
  }
  /**
   * loginuser
   */
  loginUser() {
    this.loading = true;
    let modifiedData = this.compilerService.constructLoginUserObject(
      this.loginUserFormGroup.value
    );

    this.authService.loginUser(modifiedData).subscribe(
      (response) => {
        this.loading = false;

        this.helperService.createSnackBar(
          ConstantService.successMessage.userloggedIn
        );
        
      },
      (error) => {
        this.loading = false;

        if (error.status === 401) {
          this.helperService.createSnackBar(
            ConstantService.errorMessage.noEmailExist
          );
        } else {
          this.helperService.createSnackBar(
            ConstantService.errorMessage.unknownError
          );
        }
      }
    );
  }
}
