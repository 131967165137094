import { Injectable } from '@angular/core';
import {
  LoginUser,
  RegisterUser,
} from '../models/user.models';
@Injectable({
  providedIn: 'root',
})
export class CompilerService {
  constructor() {}
  constructRegisterUserObject(userData: RegisterUser) {

    let modifiedData1 = {
      // firstname: userData.firstname,
      // name: userData.lastname,
      username: userData.email,
      // email: userData.email,
      password: userData.password,
    };
    return modifiedData1;
  }
  constructLoginUserObject(userData: LoginUser) {
    let modifiedData = {
      username: userData.username,
      password: userData.password,
    };
    return modifiedData;
  }
  constructLoginGoogleUser(userData) {
    let modifiedData = {
      userId: userData.id,
      _id: parseInt(userData.id),
      provider: 'google',
      externalId: userData.id,
      profileGoogle: {
        email: userData.email,
        firstName: userData.firstName,
        lastName: userData.lastName,
        name: userData.name,
        photoUrl: userData.photoUrl,
      },
    };
    return modifiedData;
  }
  constructUrl(data1, data2) {
    let newArray = [];
    data1.map((element) => {
      if (element.server === 'pageSpeed') {
        newArray.push({
          URL: element.url,
          overall_category: element.overallCategory,
          psi_score: element.psiScore,
          FID_0: element.goodFid,
          FID_1: element.needsImprovementFid,
          FID_2: element.poorFid,
          LCP_0: element.goodLcp,
          LCP_1: element.needsImprovementLcp,
          LCP_2: element.poorLcp,
          CLS_0: element.goodCls,
          CLS_1: element.needsImprovementCls,
          CLS_2: element.poorCls,
          updateAt: element.updateAt,
        });
      }
    });
    data2.map((element) => {
      if (element.server === 'pageSpeed') {
        newArray.push({
          URL: element.url,
          overall_category: element.overallCategory,
          psi_score: element.psiScore,
          FID_0: element.goodFid,
          FID_1: element.needsImprovementFid,
          FID_2: element.poorFid,
          LCP_0: element.goodLcp,
          LCP_1: element.needsImprovementLcp,
          LCP_2: element.poorLcp,
          CLS_0: element.goodCls,
          CLS_1: element.needsImprovementCls,
          CLS_2: element.poorCls,
          updateAt: element.updateAt,
        });
      }
    });
    return newArray;
  }

  constructScore(data) {
    let newArray = [];
    let i = 0;
    data.forEach((element) => {
      newArray.push({
        URL: data[i].URL,
      });
      i++;
    });
    return newArray;
  }
  constructScoreArray(data, URL) {
    let newArray = [];
    data.map((element) => {
      if (element.URL == URL) {
        newArray.push(element.psi_score);
      }
    });
    return newArray;
  }
  constructScoreDate(data, URL) {
    let newArray = [];
    data.map((element) => {
      if (element.URL == URL) {
        newArray.push(element.updateAt);
      }
    });
    return newArray;
  }
  constructPageUrlBar(data) {
    let newArray = [];
    data.map((element) => {
      {

        newArray.push(element.title + '-' + element.country);
      }
    });
    const filteredArray = newArray.filter(
      (ele, pos) => newArray.indexOf(ele) == pos
    );
    return filteredArray;
  }
  constructPageUrlTitle(data) {
    let newArray = [];
    data.map((element) => {
      {
        newArray.push(element.title);
      }
    });
    const filteredArray = newArray.filter(
      (ele, pos) => newArray.indexOf(ele) == pos
    );
    return filteredArray;
  }
  constructUrlCompetitors(data) {
    let newArray = [];
    data.map((element) => {
      {
        newArray.push(element.url);
      }
    });
    const filteredArray = newArray.filter(
      (ele, pos) => newArray.indexOf(ele) == pos
    );
    return filteredArray;
  }

  constructNameCompetitors(data) {
    let newArray = [];
    data.map((element) => {
      {
        newArray.push(element.title);
      }
    });
    const filteredArray = newArray.filter(
      (ele, pos) => newArray.indexOf(ele) == pos
    );
    return filteredArray;
  }
  constructPageUrlNewBar(data1, data2) {
    let newArray = [];
    data1.map((element) => {
      {
        newArray.push(element.originDomain);
      }
    });
    data2.map((element) => {
      {
        newArray.push(element.originDomain);
      }
    });
    const filteredArray = newArray.filter(
      (ele, pos) => newArray.indexOf(ele) == pos
    );
    return filteredArray;
  }
  constructUrlMobile(data1, data2) {
    let newArray = [];
    data1
      .slice(0)
      .reverse()
      .map((element) => {
        if (element.device === 'mobile' && element.server === 'pageSpeed') {
          newArray.push({
            URL: element.url,
            overall_category: element.overallCategory,
            psi_score: element.psiScore,
            FID_0: element.goodFid,
            FID_1: element.needsImprovementFid,
            FID_2: element.poorFid,
            LCP_0: element.goodLcp,
            LCP_1: element.needsImprovementLcp,
            LCP_2: element.poorLcp,
            CLS_0: element.goodCls,
            CLS_1: element.needsImprovementCls,
            CLS_2: element.poorCls,
            updateAt: element.updateAt,
          });
        }
      });
    data2
      .slice(0)
      .reverse()
      .map((element) => {
        if (element.device === 'mobile' && element.server === 'pageSpeed') {
          newArray.push({
            URL: element.url,
            overall_category: element.overallCategory,
            psi_score: element.psiScore,
            FID_0: element.goodFid,
            FID_1: element.needsImprovementFid,
            FID_2: element.poorFid,
            LCP_0: element.goodLcp,
            LCP_1: element.needsImprovementLcp,
            LCP_2: element.poorLcp,
            CLS_0: element.goodCls,
            CLS_1: element.needsImprovementCls,
            CLS_2: element.poorCls,
            updateAt: element.updateAt,
          });
        }
      });
    return newArray;
  }
  constructUrlDesktop(data1, data2) {
    let newArray = [];
    data1
      .slice(0)
      .reverse()
      .map((element) => {
        if (element.device === 'desktop' && element.server === 'pageSpeed') {
          newArray.push({
            URL: element.url,
            overall_category: element.overallCategory,
            psi_score: element.psiScore,
            FID_0: element.goodFid,
            FID_1: element.needsImprovementFid,
            FID_2: element.poorFid,
            LCP_0: element.goodLcp,
            LCP_1: element.needsImprovementLcp,
            LCP_2: element.poorLcp,
            CLS_0: element.goodCls,
            CLS_1: element.needsImprovementCls,
            CLS_2: element.poorCls,
            updateAt: element.updateAt,
          });
        }
      });
    data2
      .slice(0)
      .reverse()
      .map((element) => {
        if (element.device === 'desktop' && element.server === 'pageSpeed') {
          newArray.push({
            URL: element.url,
            overall_category: element.overallCategory,
            psi_score: element.psiScore,
            FID_0: element.goodFid,
            FID_1: element.needsImprovementFid,
            FID_2: element.poorFid,
            LCP_0: element.goodLcp,
            LCP_1: element.needsImprovementLcp,
            LCP_2: element.poorLcp,
            CLS_0: element.goodCls,
            CLS_1: element.needsImprovementCls,
            CLS_2: element.poorCls,
            updateAt: element.updateAt,
          });
        }
      });
    return newArray;
  }

  constructDataMobileCrux(data1, data2) {
    let newArray = [];
    data1
      .slice(0)
      .reverse()
      .map((element) => {
        if (element.device === 'phone' && element.server === 'crux') {
          newArray.push({
            url: element.url,
            yyyymm: element.yyyymm,
            pctGoodCls: element.pctGoodCls,
            pctAvgCls: element.pctAvgCls,
            pctSlowLcp: element.pctSlowLcp,
            good_fcp: element.good_fcp,
            needs_improvement_fcp: element.needs_improvement_fcp,
            poor_fcp: element.poor_fcp,
            pctGoodFid: element.pctGoodFid,
            pctAvgFid: element.pctAvgFid,
            pctSlowFid: element.pctSlowFid,
            pctGoodLcp: element.pctGoodLcp,
            pctAvgLcp: element.pctAvgLcp,
            pctSlowCls: element.pctSlowCls,
          });
        }
      });
    data2
      .slice(0)
      .reverse()
      .map((element) => {
        if (element.device === 'phone' && element.server === 'crux') {
          newArray.push({
            url: element.url,
            yyyymm: element.yyyymm,
            pctGoodCls: element.pctGoodCls,
            pctAvgCls: element.pctAvgCls,
            pctSlowLcp: element.pctSlowLcp,
            good_fcp: element.good_fcp,
            needs_improvement_fcp: element.needs_improvement_fcp,
            poor_fcp: element.poor_fcp,
            pctGoodFid: element.pctGoodFid,
            pctAvgFid: element.pctAvgFid,
            pctSlowFid: element.pctSlowFid,
            pctGoodLcp: element.pctGoodLcp,
            pctAvgLcp: element.pctAvgLcp,
            pctSlowCls: element.pctSlowCls,
          });
        }
      });
    return this.sortByDate(newArray).reverse();
  }
  sortByDate(arr) {
    arr.sort(function (a, b) {
      return Number(new Date(a.yyyymm)) - Number(new Date(b.yyyymm));
    });

    return arr;
  }
  constructDataDesktopCrux(data1, data2) {
    let newArray = [];

    data1
      .slice(0)
      .reverse()
      .map((element) => {
        if (element.device === 'desktop' && element.server === 'crux') {
          newArray.push({
            url: element.url,
            yyyymm: element.yyyymm,
            pctGoodCls: element.pctGoodCls,
            pctAvgCls: element.pctAvgCls,
            pctSlowLcp: element.pctSlowLcp,
            good_fcp: element.good_fcp,
            needs_improvement_fcp: element.needs_improvement_fcp,
            poor_fcp: element.poor_fcp,
            pctGoodFid: element.pctGoodFid,
            pctAvgFid: element.pctAvgFid,
            pctSlowFid: element.pctSlowFid,
            pctGoodLcp: element.pctGoodLcp,
            pctAvgLcp: element.pctAvgLcp,
            pctSlowCls: element.pctSlowCls,
          });
        }
      });
    data2
      .slice(0)
      .reverse()
      .map((element) => {
        if (element.device === 'desktop' && element.server === 'crux') {
          newArray.push({
            url: element.url,
            yyyymm: element.yyyymm,
            pctGoodCls: element.pctGoodCls,
            pctAvgCls: element.pctAvgCls,
            pctSlowLcp: element.pctSlowLcp,
            good_fcp: element.good_fcp,
            needs_improvement_fcp: element.needs_improvement_fcp,
            poor_fcp: element.poor_fcp,
            pctGoodFid: element.pctGoodFid,
            pctAvgFid: element.pctAvgFid,
            pctSlowFid: element.pctSlowFid,
            pctGoodLcp: element.pctGoodLcp,
            pctAvgLcp: element.pctAvgLcp,
            pctSlowCls: element.pctSlowCls,
          });
        }
      });

    return this.sortByDate(newArray).reverse();
  }
  constructArrayGoodLcp(data, URL) {
    let newArray = [];
    data.map((element) => {
      if (element.url == URL) {
        let pctGoodLcp = element.pctGoodLcp * 100;
        newArray.push(Math.round(pctGoodLcp));
      }
    });
    return newArray.reverse();
  }
  constructArrayNeedsImprovementLcp(data, URL) {
    let newArray = [];
    data.map((element) => {
      if (element.url == URL) {
        let pctAvgLcp = element.pctAvgLcp * 100;
        newArray.push(Math.round(pctAvgLcp));
      }
    });
    return newArray.reverse();
  }

  constructArrayPoorLcp(data, URL) {
    let newArray = [];
    data.map((element) => {
      if (element.url == URL) {
        let pctSlowLcp = element.pctSlowLcp * 100;
        newArray.push(Math.round(pctSlowLcp));
      }
    });
    return newArray.reverse();
  }
  constructArrayMonthLcp(data, URL) {
    let newArray = [];
    data.map((element) => {
      if (element.url == URL) {
        newArray.push(element.yyyymm.slice(0, 7));
      }
    });
    return newArray.reverse();
  }

  constructArrayGoodFid(data, URL) {
    let newArray = [];
    data.map((element) => {
      if (element.url == URL) {
        let pctGoodFid = element.pctGoodFid * 100;
        newArray.push(Math.round(pctGoodFid));
      }
    });
    return newArray.reverse();
  }
  constructArrayNeedsImprovementFid(data, URL) {
    let newArray = [];
    data.map((element) => {
      if (element.url == URL) {
        let pctAvgFid = element.pctAvgFid * 100;
        newArray.push(Math.round(pctAvgFid));
      }
    });
    return newArray.reverse();
  }

  constructArrayPoorFid(data, URL) {
    let newArray = [];
    data.map((element) => {
      if (element.url == URL) {
        let pctSlowFid = element.pctSlowFid * 100;
        newArray.push(Math.round(pctSlowFid));
      }
    });
    return newArray.reverse();
  }
  constructArrayGoodCls(data, URL) {
    let newArray = [];
    data.map((element) => {
      if (element.url == URL) {
        let pctGoodCls = element.pctGoodCls * 100;
        newArray.push(Math.round(pctGoodCls));
      }
    });
    return newArray.reverse();
  }
  constructArrayNeedsImprovementCls(data, URL) {
    let newArray = [];
    data.map((element) => {
      if (element.url == URL) {
        let pctAvgCls = element.pctAvgCls * 100;
        newArray.push(Math.round(pctAvgCls));
      }
    });

    return newArray.reverse();
  }

  constructArrayPoorCls(data, URL) {
    let newArray = [];
    data.map((element) => {
      if (element.url == URL) {
        let pctSlowLcp = element.pctSlowLcp * 100;
        newArray.push(Math.round(pctSlowLcp));
      }
    });

    return newArray.reverse();
  }

  constructInternalUrl(data: any, url: string) {
    let x: any;
    data.map((element) => {
      if (url === element.originDomain) {
        x = element.internalURLs;
      }
    });
    return x;
  }

  constructInternalUrlfinalScreenshot(data, internalURL) {
    let newArray = [];

    data.map((element) => {
      if (element.url == internalURL && element.server === 'pageSpeed') {
        newArray.push(element.finalScreenshot);
      }
    });

    return newArray;
  }
  constructNewData(data1, data2) {
    let newArray = [];

    data1.map((element) => {
      newArray.push(element);
    });
    data2.map((element) => {
      newArray.push(element);
    });
    return newArray;
  }
  constructData(data) {
    let newArray = [];

    data.map((element) => {
      newArray.push(element);
    });

    return newArray;
  }
  /**
   *
   */
}
