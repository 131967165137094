import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { delay } from 'rxjs/operators';
import { ConstantService } from 'src/app/shared/constant/constant.service';
import { countries } from 'src/app/shared/models/country.model';
import {
  DataCrux,
  metricOverTime,
  PageSpeedData,
  PageSpeedDataOverview,
  TypeData,
} from 'src/app/shared/models/pagespeed.model';
import { HelpersService } from '../../helpers/helpers.service';
import { co2 } from '@tgwf/co2';

const oneByte = new co2({ model: '1byte' });
@Injectable({
  providedIn: 'root',
})
export class PageSpeedInsigthService {
  titleCls: string;
  status: string;
  color: string;
  titleFcp: string;
  goodLcp: string;
  goodTtfp: string;
  titleLcp: string;
  titleTtfp: string;
  title: string;
  isMobile: boolean;
  titleInp: string;
  googdTtfb: string;
  titleTtfb: string;

  constructor(
    private httpClient: HttpClient,
    private helpersService: HelpersService
  ) {}
  ngOnInit(): void {}
  Apicall(url) {
    return this.httpClient.get<any>(url).pipe(delay(1000)).toPromise();
  }
  mobileShow(isMobile) {
    this.isMobile = isMobile;
    return isMobile;
  }
  limiteUrlName(str) {
    var delimiteur = ['/', '//'];
    var tempChar = delimiteur[0]; // We can use the first token as a temporary join character
    for (var i = 1; i < delimiteur.length; i++) {
      str = str.split(delimiteur[i]).join(tempChar);
    }
    str = str.split(tempChar);
    let pageUrl = str.slice(2).join('/');
    pageUrl = pageUrl.length <= 15 ? pageUrl : pageUrl.slice(0, 17) + '...';
    return pageUrl;
  }
  getCountry(data: any) {
    let country = countries;
    let x: any;
    country.map((elem) => {
      if (data[data.length - 1] != undefined) {
        if (elem.alpha2Code === data[data.length - 1].country.toUpperCase()) {
          x = elem;
        }
      } else {
        if (elem.alpha2Code === data.country.toUpperCase()) {
          x = elem;
        }
      }
    });
    return x;
  }
  getCruxData(data: any, metric: string, legend): DataCrux {
    let newArrayDate = [];
    let newArray1 = [];
    let newArray2 = [];
    let newArray3 = [];
    let newArray4 = [];
    let data1 = this.sortByDate(data);
    let diffp75: any;
    const good = this.helpersService.getMetricKey('pctGood', metric);
    const avg = this.helpersService.getMetricKey('pctAvg', metric);
    const slow = this.helpersService.getMetricKey('pctSlow', metric);
    const p75 = this.helpersService.getMetricKey('p75', metric);
    data1.map((element) => {
      if (
        element[good] * 100 !== 0 &&
        element[avg] * 100 !== 0 &&
        element[slow] * 100 !== 0
      ) {
        newArrayDate.push(element.yyyymm);
        newArray1.push((element[good] * 100).toFixed(1));
        newArray2.push((element[avg] * 100).toFixed(1));
        newArray3.push((element[slow] * 100).toFixed(1));
        metric === 'cls' ? newArray4.push(element[p75] !== undefined ? +element[p75].toFixed(2) : 0): newArray4.push(element[p75]) ;
      }
    });

    diffp75 =
      newArray4.length >= 2
        ? newArray4[newArray4.length - 1] - newArray4[newArray4.length - 2]
        : 0;
    if (Number.isNaN(diffp75)) {
      diffp75 = 0;
    }
    //console.log(metric, legend)

    let dataCrux: DataCrux = {
      good: newArray1,
      nameGood: legend?.good,
      needsImprovement: newArray2,
      nameNeedsImprovement: legend?.moderate,
      poor: newArray3,
      namePoor: legend?.poor,
      month: newArrayDate,
      p75: newArray4,
      diffp75: diffp75,
      date: newArrayDate,
    };

    return dataCrux;
  }

  getCompetitors(data: any, url, name, dateList) {
    let newArray = [];
    let newArray2 = [];

    data.map((element) => {
      if (element.server === 'crux') {
        newArray2.push(element);
      }
    });

    dateList.forEach(() => {
      newArray.push('00.0');
    });

    this.sortByDate(newArray2).map((element) => {
      if (element.url === url) {
        let index = dateList.findIndex((date) => date === element.yyyymm);
        let nameMetric =
          name !== 'TTFP'
            ? 'pctGood' +
              this.helpersService.capitalizeFirstLetter(name.toLowerCase())
            : 'pctGood' + name;
        // console.log(element[nameMetric])
        if (index >= 0) {
          newArray[index] = (element[nameMetric] * 100).toFixed(1);
        }
      }
    });
    // console.log(newArray)
    return { name: url, data: newArray };
  }
  getUXRCompetitors(data: any, url, name, dateList) {
    let newArray = [];
    let newArray2 = [];
    data.map((element) => {
      if (element.server === 'crux') {
        newArray2.push(element);
      }
    });
    dateList.forEach(() => {
      newArray.push('00.0');
    });
    this.sortByDate(newArray2).map((element) => {
      if (element.url === url) {
        let index = dateList.findIndex((date) => date === element.yyyymm);
        if (index >= 0) {
          newArray[index] = element[name];
        }
      }
    });

    // console.log(newArray)
    return { name: url, data: newArray };
  }

  getCompetitorsDataPageSpeed(data: any, url, type) {
    let res: TypeData = { good: 0, needsImprovement: 0, poor: 0 };
    let good, needsImprovement, poor;

    data.map((element) => {
      if (element.url === url) {
        if (type.toLowerCase() === 'ttfp' || type.toLowerCase() === 'inp') {
          good = this.helpersService.getMetricKey('pctGood', type);
          needsImprovement = this.helpersService.getMetricKey('pctAvg', type);
          poor = this.helpersService.getMetricKey('pctSlow', type);
          res.good = Math.round(element[good] * 1000) / 10;
          res.needsImprovement =
            Math.round(element[needsImprovement] * 1000) / 10;
          res.poor = Math.round(element[poor] * 1000) / 10;
        } else {
          good = this.helpersService.getMetricKey('good', type.toLowerCase());
          needsImprovement = this.helpersService.getMetricKey(
            'needsImprovement',
            type.toLowerCase()
          );
          poor = this.helpersService.getMetricKey('poor', type.toLowerCase());
          res.good = element[good];
          res.needsImprovement = element[needsImprovement];
          res.poor = element[poor];
        }
      }
    });

    return res;
  }

  getCompetitorsDataCrux(data, url, type) {
    let res: TypeData = { good: 0, needsImprovement: 0, poor: 0 };
    let good, needsImprovement, poor;

    let newArray = [];
    data.map((dt) => {
      if (dt.url === url) newArray.push(dt);
    });
    if (newArray.length !== 0) {
      const lastElement = newArray[newArray.length - 1];

      good = this.helpersService.getMetricKey('pctGood', type);
      needsImprovement = this.helpersService.getMetricKey('pctAvg', type);
      poor = this.helpersService.getMetricKey('pctSlow', type);
      res.good = Math.round(lastElement[good] * 1000) / 10 || 0;
      res.needsImprovement =
        Math.round(lastElement[needsImprovement] * 1000) / 10 || 0;
      res.poor = Math.round(lastElement[poor] * 1000) / 10 || 0;
    }

    return res;
  }

  getCompetitorsTTFB(data: any, url) {
    let res: TypeData = { good: 0, needsImprovement: 0, poor: 0 };

    data.map((element) => {
      if (element.server === 'crux' && element.url === url) {
        res.good = Math.round(element.pctGoodTTFP * 1000) / 10;
        res.needsImprovement = Math.round(element.pctAvgTTFP * 1000) / 10;
        res.poor = Math.round(element.pctSlowTTFP * 1000) / 10;
      }
    });
    return res;
  }

  getdateCompeitor(data: any, url) {
    let newArray = [];
    let data1 = this.sortByDate(data);
    data1.map((element) => {
      if (element.server === 'crux' && element.url === url) {
        newArray.push(element.yyyymm);
      }
    });
    const filteredArray = newArray.filter(
      (ele, pos) => newArray.indexOf(ele) == pos
    );
    return filteredArray;
  }

  getCruxDataTtfp(
    data: any,
    nameGood,
    nameNeedsImprovement,
    namePoor
  ): DataCrux {
    let newArrayDate = [];
    let newArray1 = [];
    let newArray2 = [];
    let newArray3 = [];
    let newArray4 = [];
    let data1 = this.sortByDate(data);

    data1.map((element) => {
      newArrayDate.push(element.yyyymm);
      newArray1.push((element.pctGoodTTFP * 100).toFixed(1));
      newArray2.push((element.pctAvgTTFP * 100).toFixed(1));
      newArray3.push((element.pctSlowTTFP * 100).toFixed(1));
      newArray4.push(Math.round(element.p75TTFP));
    });
    let diffp75 =
      newArray4.length >= 2
        ? newArray4[newArray4.length - 1] - newArray4[newArray4.length - 2]
        : 0;
    let dataCrux: DataCrux = {
      good: newArray1,
      nameGood: nameGood,
      needsImprovement: newArray2,
      nameNeedsImprovement: nameNeedsImprovement,
      poor: newArray3,
      namePoor: namePoor,
      month: newArrayDate,
      p75: newArray4[newArray4.length - 1],
      diffp75: diffp75,
    };
    return dataCrux;
  }

  getUXRRanking(cruxData) {
    const sites: any = {};

    cruxData.forEach((cd) => {
      if (!sites[cd.url]?.length) {
        sites[cd.url] = [cd];
      } else {
        sites[cd.url] = [...sites[cd.url], cd];
      }
    });

    const sortedCrux = [];
    Object.keys(sites).forEach((k) => {
      const d = sites[k];
      const current = d?.[d?.length - 1];
      const prev = d?.[d?.length - 2];
      sortedCrux.push({
        title: current.title,
        url: k,
        ttfb: current.p75TTFP,
        lcp: current.p75Lcp,
        inp: current.p75Inp,
        cls: current.p75Cls,
        uxr: current.uxr,
        uxrDiff: (current.uxr - prev.uxr).toFixed(2),
      });
    });

    return sortedCrux.sort((a, b) => b.uxr - a.uxr);
  }

  getTitle(value) {
    let title: string;
    if (value > 75) title = ConstantService.titleGraphicimage.good;
    else if (value > 50) title = ConstantService.titleGraphicimage.average;
    else title = ConstantService.titleGraphicimage.poor;
    return title;
  }

  getTitle2(title) {
    return ConstantService.titleGraphicimage[title];
  }
  getAverageValue(value) {
    if (value > 75) return 'FAST';
    else if (value >= 50 && value <= 75) return 'AVERAGE';
    else if (value < 50) return 'SLOW';
    return 'N/A';
  }

  getNeedImprovement(array: Array<any>) {
    let improvement: string = '';
    if (array.length < 0 || array === null || array === undefined)
      improvement = '';
    array.map((elt) => {
      let ptVirgule = elt === array[array.length - 1] ? ' ' : ';';
      improvement +=
        elt.metric.toUpperCase() + ' (' + elt.value + ')' + ptVirgule + ' ';
    });
    return improvement;
  }

  getSpeedDataOverview(data: any): any {
    if (data.overallCategory === 'FAST') {
      this.status = 'passes';
      this.color = 'green';
    } else {
      this.status = 'does not pass ';
      this.color = 'red';
    }

    let PageSpeedDataOverview: PageSpeedDataOverview = {
      cls: {
        good: Math.round(data.pctGoodCls * 1000) / 10 || 0,
        needsImprovement: Math.round(data.pctAvgCls * 1000) / 10 || 0,
        poor: Math.round(data.pctSlowCls * 1000) / 10 || 0,
        //p75: data?.p75Cls,
      },
      lcp: {
        good: Math.round(data.pctGoodLcp * 1000) / 10 || 0,
        needsImprovement: Math.round(data.pctAvgLcp * 1000) / 10 || 0,
        poor: Math.round(data.pctSlowLcp * 1000) / 10 || 0,
        //p75: data?.p75Lcp,
      },
      fcp: {
        good: Math.round(data.pctGoodFcp * 1000) / 10 || 0,
        needsImprovement: Math.round(data.pctAvgFcp * 1000) / 10 || 0,
        poor: Math.round(data.pctSlowFcp * 1000) / 10 || 0,
        //p75: data?.p75TTFP,
      },
      ttfp: {
        good: Math.round(data.pctGoodTTFP * 1000) / 10 || 0,
        needsImprovement: Math.round(data.pctAvgTTFP * 1000) / 10 || 0,
        poor: Math.round(data.pctSlowTTFP * 1000) / 10 || 0,
        //p75: data?.p75TTFP,
      },
      inp: {
        good: Math.round(data.pctGoodInp * 1000) / 10 || 0,
        needsImprovement: Math.round(data.pctAvgInp * 1000) / 10 || 0,
        poor: Math.round(data.pctSlowInp * 1000) / 10 || 0,
        //p75: data?.p75Inp,
      },
      title: {
        cls: this.getTitle(
          data[this.helpersService.getMetricKey('good', 'cls')]
        ),
        fcp: this.getTitle(
          data[this.helpersService.getMetricKey('good', 'fcp')]
        ),
        fid: this.getTitle(
          data[this.helpersService.getMetricKey('good', 'fid')]
        ),
        lcp: this.getTitle(
          data[this.helpersService.getMetricKey('good', 'lcp')]
        ),
        ttfp: this.getTitle(
          data[this.helpersService.getMetricKey('good', 'ttfp')]
        ),
        inp: this.getTitle(
          data[this.helpersService.getMetricKey('good', 'inp')]
        ),
      },
      status: this.status,
      color: this.color,
    };

    return PageSpeedDataOverview;
  }
  getSpeedDataMetricOverTime(data): metricOverTime {
    let newArrayDate = [];
    let newArray1 = [];
    let newArray2 = [];
    let newArray3 = [];
    data.map((element) => {
      newArrayDate.push(element.updateAt);
      newArray1.push(element.interactive);
      newArray2.push(element.speedIndex);
      newArray3.push(element.totalBlockingTime);
    });
    let metricOverTime: metricOverTime = {
      interactive: {
        name: 'interactive',
        date: newArrayDate,
        series: newArray1,
      },
      speedIndex: {
        name: 'speedIndex',
        date: newArrayDate,
        series: newArray2,
      },
      totalBlockingTime: {
        name: 'totalBlockingTime',
        date: newArrayDate,
        series: newArray3,
      },
    };
    return metricOverTime;
  }
  getIcon(dataGood): string {
    if (dataGood > 75) {
      this.title = ConstantService.titleGraphicimage.good;
    } else if (dataGood > 50) {
      this.title = ConstantService.titleGraphicimage.average;
    } else if (dataGood < 50) {
      this.title = ConstantService.titleGraphicimage.poor;
    }
    return this.title;
  }
  getSpeedData(data: any): PageSpeedData {
    let pageSpeedData: PageSpeedData = {
      image: data.finalScreenshot,
      homeUrl: data.url,
      title: data.title,
      uxr: data.uxr,
      cls: {
        good: data.goodCls,
        needsImprovement: data.needsImprovementCls,
        poor: data.poorCls,
        p75: data.p75Cls,
      },
      lcp: {
        good: data.goodLcp,
        needsImprovement: data.needsImprovementLcp,
        poor: data.poorLcp,
        p75: data.p75Lcp,
      },
      fid: {
        good: data.goodFid,
        needsImprovement: data.needsImprovementFid,
        poor: data.poorFid,
        p75: data.p75Fid,
      },
      fcp: {
        good: data.goodFcp,
        needsImprovement: data.needsImprovementFcp,
        poor: data.poorFcp,
        p75: data.p75Fcp,
      },
      ttfp: {
        good: data.pctGoodTTFP,
        needsImprovement: data.pctAvgTTFP,
        poor: data.pctSlowTTFP,
        p75: data.p75TTFP,
      },
      inp: {
        good: data.goodInp,
        needsImprovement: data.needsImprovementInp,
        poor: data.goodInp,
        p75: data.p75Inp,
      },
      ttfb: {
        good: data.goodTtfb,
        needsImprovement: data.needsImprovementTtfb,
        poor: data.goodTtfb,
        p75: data.p75Ttfb,
      },
      overall: data.overallCategory,

      countInternalUrls: data.countinternalpages,
      projectId: data.projectId,
      country: data.country,
    };
    let overallValue =
      (pageSpeedData.cls.good +
        pageSpeedData.lcp.good +
        pageSpeedData.fid.good) /
      3;
    pageSpeedData.overall = this.getAverageValue(overallValue);
    return pageSpeedData;
  }

  getCruxSpeedData(data: any): PageSpeedData {
    let pageSpeedData: PageSpeedData = {
      image: data.finalScreenshot,
      homeUrl: data.url,
      title: data.title,
      uxr: data.uxr,
      cls: {
        good: Math.round(data.pctGoodCls * 1000) / 10 || 0,
        needsImprovement: Math.round(data.pctAvgCls * 1000) / 10 || 0,
        poor: Math.round(data.pctSlowCls * 1000) / 10 || 0,
        p75: data?.p75Cls,
      },
      lcp: {
        good: Math.round(data.pctGoodLcp * 1000) / 10 || 0,
        needsImprovement: Math.round(data.pctAvgLcp * 1000) / 10 || 0,
        poor: Math.round(data.pctSlowLcp * 1000) / 10 || 0,
        p75: data?.p75Lcp,
      },
      fid: {
        good: Math.round(data.pctGoodFid * 1000) / 10 || 0,
        needsImprovement: Math.round(data.pctAvgFid * 1000) / 10 || 0,
        poor: Math.round(data.pctSlowFid * 1000) / 10 || 0,
        p75: data?.p75Fid,
      },
      fcp: {
        good: Math.round(data.pctGoodFcp * 1000) / 10 || 0,
        needsImprovement: Math.round(data.pctAvgFcp * 1000) / 10 || 0,
        poor: Math.round(data.pctSlowFcp * 1000) / 10 || 0,
        p75: data?.p75TTFP,
      },
      ttfp: {
        good: Math.round(data.pctGoodTTFP * 1000) / 10 || 0,
        needsImprovement: Math.round(data.pctAvgTTFP * 1000) / 10 || 0,
        poor: Math.round(data.pctSlowTTFP * 1000) / 10 || 0,
        p75: data?.p75TTFP,
      },
      inp: {
        good: Math.round(data.pctGoodInp * 1000) / 10 || 0,
        needsImprovement: Math.round(data.pctAvgInp * 1000) / 10 || 0,
        poor: Math.round(data.pctSlowInp * 1000) / 10 || 0,
        p75: data?.p75Inp,
      },
      overall: data.overallCategory,

      countInternalUrls: data.countinternalpages,
      projectId: data.projectId,
      country: data.country,
    };
    let overallTable: string[] = [
      this.getAverageValue(pageSpeedData.cls.good),
      this.getAverageValue(pageSpeedData.lcp.good),
      this.getAverageValue(pageSpeedData.fid.good),
    ];

    if (overallTable.every((elt) => elt === 'FAST'))
      pageSpeedData.overall = 'FAST';
    else {
      if (overallTable.some((elt) => elt === 'SLOW'))
        pageSpeedData.overall = 'SLOW';
      else if (overallTable.some((elt) => elt === 'AVERAGE'))
        pageSpeedData.overall = 'AVERAGE';
      else pageSpeedData.overall = 'N/A';
    }

    return pageSpeedData;
  }

  getMetricSpeedData(data: any, metric: string) {
    let pageSpeedData: PageSpeedData = {
      image: data.finalScreenshot,
      homeUrl: data.url,
      title: data.title,
      overall: data.overallCategory,
      countInternalUrls: data.countinternalpages,
      projectId: data.projectId,
      country: data.country,
    };

    metric = metric.toLowerCase();
    let metric2 = metric === 'ttfp' ? 'ttfb' : metric;

    pageSpeedData[metric] = {
      good: data[this.helpersService.getMetricKey('good', metric2)] | 0,
      needsImprovement:
        data[this.helpersService.getMetricKey('needsImprovement', metric2)] | 0,
      poor: data[this.helpersService.getMetricKey('poor', metric2)] | 0,
      // }
    };
    return pageSpeedData;
  }

  getUrlSpeedData(url: string, result: any, device: string) {
    let newArray: any;
    for (let elt in result) {
      if (result[elt].url === url && result[elt].device === device)
        newArray = result[elt];
    }
    return newArray;
  }

  getDatasPerDevice(result, device) {
    let newArray = [];
    result.map((element) => {
      if (device === 'desktop') {
        if (element.device === device) {
          newArray.push(element);
        }
      } else {
        if (element.device !== 'desktop') {
          newArray.push(element);
        }
      }
    });

    return newArray;
  }
  getUrlSpeedData1(result, device) {
    let newArray: any = [];
    result.map((element) => {
      if (element.device === device) {
        newArray.push(element);
      }
    });
    return this.sortByDate(newArray);
  }
  getTTFP(result, device) {
    let newArray: any = [];
    let dev;
    var d = new Date();
    var month = d.getMonth() - 1;
    var year = d.getFullYear();
    if (device === 'mobile') {
      dev = 'phone';
    } else {
      dev = 'desktop';
    }
    result.map((element) => {
      if (
        element.device === dev &&
        element.yyyymm === year + '-' + '0' + month
      ) {
        newArray.push(element);
      }
    });
    return this.sortByDate(newArray);
  }
  sortByDate(arr) {
    arr.sort(function (a, b) {
      return Date.parse(a.yyyymm + '-30') - Date.parse(b.yyyymm + '-30');
    });
    return arr;
  }
  chartOptions( data: any, horizontal: boolean = true) {
    //console.log("in services", data);
    return {
      series: [
        {
          name: 'Good',
          data: [data.good?.toFixed(2)],
        },
        {
          name: 'Needs improvement',
          data: [data.needsImprovement?.toFixed(2)],
        },
        {
          name: 'Poor',
          data: [data.poor?.toFixed(2)],
        },
      ],
      chart: {
        type: 'bar',
        height: 200,
        width: '100%',
        stacked: true,
        ...(!horizontal && {
          toolbar: {
            show: true,
            tools: {
              download: true,
            },
          },
        }),
      },
      plotOptions: {
        bar: {
          horizontal,
          ...(!horizontal && { columnWidth: '99%' }),
        },
      },
      stroke: {
        width: 1,
        colors: ['#fff'],
      },
      title: {
        text: '',
      },
      xaxis: {
        categories: [''],
      },
      yaxis: {
        max: 100,
      },
      tooltip: {
        y: {
          formatter: function (val) {
            return Math.round(val.toFixed());
          },
        },
      },
      fill: {
        colors: ['#15cd6a', '#fba400', '#fa4f44'],
      },
      ...(horizontal && { legend: { show: false } }),
      ...(!horizontal && {
        legend: {
          position: 'top',
          // show :false,
          offsetY: 20,
          useSeriesColors: true,
          labels: {
            opacity: 1,
            colors: ['#15cd6a', '#fa4f44', '#fba400'],
          },
          markers: {
            width: 12,
            height: 12,
            strokeWidth: 0,
            opacity: 0.6,

            fillColors: ['#0CCE6B', '#FFA400', '#FF4E42'],
          },
        },
      }),

      dataLabels: {
        enabled: true,
        style: {
          colors: ['#666666']
        }
      },
      labels: {
        colors: undefined,
        useSeriesColors: false,
      },
    };
  }
  chartOptionsForCompare( data: any,siteTitle1:string,siteTitle2:string, horizontal: boolean = true) {
    //console.log("in services", data);
    return {
      series: [
        {
          name: 'Good',
          data: data.good.map(value => parseFloat(value).toFixed(2)),
        },
        {
          name: 'Needs improvement',
          data: data.needsImprovement.map(value => parseFloat(value).toFixed(2)),
        },
        {
          name: 'Poor',
          data: data.poor.map(value => parseFloat(value).toFixed(2)),
        },
      ],
      chart: {
        type: 'bar',
        height: 200,
        stacked: true,
        ...(!horizontal && {
          toolbar: {
            show: true,
            tools: {
              download: true,
            },
          },
        }),
      },
      plotOptions: {
        bar: {
          horizontal,
          ...(!horizontal && { columnWidth: '99%' }),
        },
      },
      stroke: {
        width: 1,
        colors: ['#fff'],
      },
      title: {
        text: '',
      },
      xaxis: {
        categories:[siteTitle1,siteTitle2],
      },
      yaxis: {
        max: 100,
      },
      tooltip: {
        y: {
          formatter: function (val) {
            return Math.round(val.toFixed());
          },
        },
      },
      fill: {
        colors: ['#15cd6a', '#fba400', '#fa4f44'],
      },
      ...(horizontal && { legend: { show: false } }),
      ...(!horizontal && {
        legend: {
          position: 'top',
          // show :false,
          offsetY: 20,
          useSeriesColors: true,
          labels: {
            opacity: 1,
            colors: ['#15cd6a', '#fa4f44', '#fba400'],
          },
          markers: {
            width: 12,
            height: 12,
            strokeWidth: 0,
            opacity: 0.6,

            fillColors: ['#0CCE6B', '#FFA400', '#FF4E42'],
          },
        },
      }),
      dataLabels: {
        enabled: true,
      },
      labels: {
        colors: undefined,
        useSeriesColors: false,
      },
    };
  }
  chartOptionsGPN(datagood, dataneedsImprovement, datapoor) {
    return {
      series: [
        {
          name: 'Good',
          data: [datagood],
        },
        {
          name: 'Needs improvement',
          data: [dataneedsImprovement],
        },
        {
          name: 'Poor',
          data: [datapoor],
        },
      ],
      chart: {
        type: 'bar',
        height: 200,
        stacked: true,
        stackType: '100%',
      },
      plotOptions: {
        bar: {
          horizontal: true,
        },
      },
      stroke: {
        width: 1,
        colors: ['#fff'],
      },
      title: {
        text: '',
      },
      xaxis: {
        categories: [''],
      },
      tooltip: {
        x: {
          format: 'dd/MM/yy',
        },
        y: {
          formatter: function (val) {
            return val + '%';
          },
        },
      },
      fill: {
        colors: ['#15cd6a', '#fba400', '#fa4f44'],
      },
      legend: {
        show: false,
      },
      labels: {
        colors: undefined,
        useSeriesColors: false,
      },
    };
  }

  chartOptionsGPNCompare(dataname1, dataname2, datagood1, dataneedsImprovement1, datapoor1, datagood2, dataneedsImprovement2, datapoor2) {
    return {
      series: [
        {
          name: 'Good',
          data: [datagood1,datagood2],
        },
        {
          name: 'Needs improvement',
          data: [dataneedsImprovement1, dataneedsImprovement2],
        },
        {
          name: 'Poor',
          data: [datapoor1, datapoor2],
        },
      ],
      chart: {
        type: 'bar',
        height: 200,
        stacked: true,
        stackType: '100%',
      },
      plotOptions: {
        bar: {
          horizontal: true,
        },
      },
      stroke: {
        width: 1,
        colors: ['#fff'],
      },
      title: {
        text: '',
      },
      xaxis: {
        categories: [dataname1,dataname2],
      },
      tooltip: {
        x: {
          format: 'dd/MM/yy',
        },
        y: {
          formatter: function (val) {
            return val + '%';
          },
        },
      },
      fill: {
        colors: ['#15cd6a', '#fba400', '#fa4f44'],
      },
      legend: {
        position: 'right',
        // show :false,
        offsetY: 20,
        useSeriesColors: true,
        labels: {
          opacity: 1,
          colors: ['#15cd6a', '#fba400', '#fa4f44'],
        },
        markers: {
          width: 12,
          height: 12,
          strokeWidth: 0,
          opacity: 0.6,

          fillColors: ['#0CCE6B', '#FFA400', '#FF4E42'],
        },
      },
    };
  }

  pageViewAndSessionOvertime(dataSeries, labels) {
    return {
      series: dataSeries,
      chart: {
        height: 350,
        type: 'area',
        toolbar: {
          show: true,
          tools: {
            download: true,
          },
        },
      },

      dataLabels: {
        enabled: true,
      },
      stroke: {
        curve: 'smooth',
      },

      xaxis: {
        //type: 'datetime',
        categories: labels,
      },
    };
  }
  chartOptionsmetricOverTime(dataname, dataseries, datadate, annotation?, filter?:string) {
    let formattedCategories;
    if (filter) {
      // if RUM metric overview
      formattedCategories = datadate.map(month => this.formatDate(month, filter));
  } else {
      // if Crux metric over view
      formattedCategories = datadate?.month || datadate;
  }

    return {
      series: [
        {
          name: dataname,
          data: dataseries,
        },
      ],
      title: {
        text: dataname,
        align: 'left',
      },
      chart: {
        height: 350,
        type: 'area',
        toolbar: {
          show: true,
          tools: {
            download: true,
          },
        },
      },

      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: 'smooth',
      },
      xaxis: {
        categories: formattedCategories,
        type: 'category',
      },
      tooltip: {
        // x: {
        //   format: 'dd/MM/yy',
        // },
        y: {
          formatter: function (val) {
            return val + '%';
          },
        },
      },
      annotations: {
        xaxis: annotation,
      },
    };
  }

  chartOptionsmetricOverTimeForCompare(dataname,dataname1,dataname2,dataseries1, dataseries2, datadate,annotation?, filter?:string) {
    let formattedCategories;
    if (filter) {
        // if RUM metric overview
        formattedCategories = datadate.map(month => this.formatDate(month, filter));
    } else {
        // if Crux metric over view
        formattedCategories = datadate
    }
    return {
      series: [
        {
          name: dataname1,
          data: dataseries1,
        },
        {
          name: dataname2,
          data: dataseries2,
        },
      ],
      title: {
        text: dataname,
        align: 'left',
      },
      chart: {
        height: 350,
        type: 'area',
        toolbar: {
          show: true,
          tools: {
            download: true,
          },
        },
      },

      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: 'smooth',
      },
       xaxis: {
        categories: datadate,
        type: 'category',
        tickAmount : 'dataPoints',
        formatter: function (value, timestamp, opts) {
          return opts.dateFormatter(new Date(timestamp)).format('MM-YYYY');
        },

      },
      // xaxis: {
      //   categories: formattedCategories,
      //   type: 'category',
      // },
      tooltip: {
        // x: {
        //   format: 'dd/MM/yy',
        // },
        y: {
          formatter: function (val) {
            return val + '%';
          },
        },
      },
      annotations: {
        xaxis: annotation,
      },
    };
  }

  chartOptionsmetricOverTimeCompare(dataname1,dataname2, dataseries1, dataseries2, datadate, annotation?, filter?:string) {
    let formattedCategories;
    if (filter) {
      // if RUM metric overview
      formattedCategories = datadate.map(month => this.formatDate(month, filter));
  } else {
      // if Crux metric over view
      formattedCategories = datadate;
  }
    return {
      series: [
        {
          name: dataname1,
          data: dataseries1,
        },
        {
          name: dataname2,
          data: dataseries2,
        },
      ],
      chart: {
        height: 350,
        type: 'area',
        toolbar: {
          show: true,
          tools: {
            download: true,
          },
        },
      },

      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: 'smooth',
      },
      xaxis: {
        categories: formattedCategories,
        type: 'category',
      },
      tooltip: {
        x: {
          format: 'dd/MM/yy',
        },
        // y: {
        //   formatter: function (val) {
        //     return val + '%';
        //   },
        // },
      },
    };
  }
  chartOptionCrux(data, annotation, filter?:string) {
    let formattedCategories;
    if (filter) {
        // if RUM metric overview
        formattedCategories = data.month.map(month => this.formatDate(month, filter));
    } else {
        // if Crux metric over view
        formattedCategories = data.month;
    }

    return {
      series: [
        {
          name: data.nameGood,
          data: data.good,
        },
        {
          name: data.nameNeedsImprovement,
          data: data.needsImprovement,
        },
        {
          name: data.namePoor,
          data: data.poor,
        },
      ],

      chart: {
        type: 'bar',
        height: 400,
        stacked: true,
        // stackType: "100%",
        toolbar: {
          show: true,
          tools: {
            download: true,
          },
        },
        zoom: {
          enabled: true,
        },
      },
      annotations: {
        points: annotation,
      },

      responsive: [
        {
          breakpoint: 480,
          options: {
            legend: {
              position: 'bottom',
              offsetX: -10,
              offsetY: 0,
            },
          },
        },
      ],
      plotOptions: {
        bar: {
          horizontal: this.isMobile,
          columnWidth: '99%',
        },
      },
      dataLabels: {
        enabled: true,
        formatter: function (val) {
          return val.toFixed(1) + '%';
        },
        style: {
          fontSize: data?.good?.length > 14 ? '9px' : '12px',
          colors: ['#304758'],
        },
      },

      // xaxis: {
      //   categories: data.month,
      //   type: 'category',
      //   tickAmount : 'dataPoints',
      //   formatter: function (value, timestamp, opts) {
      //     return opts.dateFormatter(new Date(timestamp)).format('MM-YYYY');
      //   },

      // },
      xaxis: {
        categories: formattedCategories,
        type: 'category',
      },

      yaxis: {
        max: 100,
      },
      legend: {
        position: 'top',
        // show :false,
        offsetY: 20,
        useSeriesColors: true,
        labels: {
          opacity: 1,
          colors: ['#15cd6a', '#fba400', '#fa4f44'],
        },
        markers: {
          width: 12,
          height: 12,
          strokeWidth: 0,
          opacity: 0.6,

          fillColors: ['#0CCE6B', '#FFA400', '#FF4E42'],
        },
      },
      fill: {
        opacity: 0.6,
        colors: ['#0CCE6B', '#FFA400', '#FF4E42'],
      },

      stroke: {
        colors: ['#fff'],
        width: 1000,
      },
    };
  }

  chartOptionCruxForCompare(data, annotation, filter?:string) {
    let formattedCategories;
    if (filter) {
        // if RUM metric overview
        formattedCategories = data.month.map(month => this.formatDate(month, filter));
    } else {
        // if Crux metric over view
        formattedCategories = data.month;
    }

    return {
      series: [
        {
          name: data.nameGood1,
          group: "data1",
          data: data.good1,
        },
        {
          name:  data.nameGood2 ,
          group: "data2",
          data: data.good2,
        },
        {
          name: data.nameNeedsImprovement1,
          group: "data1",
          data: data.needsImprovement1,
        },
         {
          name: data.nameNeedsImprovement2,
          group: "data2",
          data: data.needsImprovement2,
        },
        {
          name:  data.namePoor1,
          group: "data1",
          data: data.poor1
        },
        {
          name:  data.namePoor2,
          group: "data2",
          data: data.poor2
        },
      ],
      annotations: {
        points: annotation,
      },
      xaxis: {
        categories: data.month,
        type: 'category',
        tickAmount : 'dataPoints',
        formatter: function (value, timestamp, opts) {
          return opts.dateFormatter(new Date(timestamp)).format('MM-YYYY');
        },

      },
      // xaxis: {
      //   categories: formattedCategories,
      //   type: 'category',
      // },

      chart: {
        type: "bar",
        height: 350,
        stacked: true
      },
      stroke: {
        width: 1,
        colors: ["#fff"]
      },
      dataLabels: {
        enabled: true,

        formatter: function (val) {
          return val.toFixed(1) + '%';
        },
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: '85%',
        }
      },
      fill: {
          opacity: 1,
          colors: ['#15cd6a', '#82df9b', '#fba400', '#ffc26b','#fa4f44','#ff907f'],
      },
      yaxis: {
        max: 100
      },
      legend: {
        position: "right",
        horizontalAlign: "left",
        offsetY: 20,
        useSeriesColors: true,
        labels: {
          opacity: 2,
          colors: ['#15cd6a', '#82df9b', '#fba400', '#ffc26b','#fa4f44','#ff907f'],
        },
        markers: {
          width: 12,
          height: 12,
          strokeWidth: 0,
          opacity: 0.6,

          fillColors: ['#15cd6a', '#82df9b', '#fba400', '#ffc26b','#fa4f44','#ff907f'],
        },
      }
    };
  }



  chartOptionNavType(data) {
    return {
      series: [
        {
          name: 'Back Forward',
          data: data.nav_back_forward,
        },
        {
          name: 'Back Forward cache',
          data: data.nav_back_forward_cache,
        },
        {
          name: 'Navigate',
          data: data.nav_navigate,
        },
        {
          name: 'Navigate cache',
          data: data.nav_navigate_cache,
        },
        {
          name: 'reload',
          data: data.nav_reload,
        },
        {
          name: 'restore',
          data: data.nav_restore,
        },
        {
          name: 'prerender',
          data: data.nav_prerender,
        },
      ],

      chart: {
        type: 'bar',
        height: 400,
        stacked: true,
        // stackType: "100%",
        toolbar: {
          show: true,
          tools: {
            download: true,
          },
        },
        zoom: {
          enabled: true,
        },
      },

      responsive: [
        {
          breakpoint: 480,
          options: {
            legend: {
              position: 'bottom',
              offsetX: -10,
              offsetY: 0,
            },
          },
        },
      ],
      plotOptions: {
        bar: {
          horizontal: this.isMobile,
          columnWidth: '99%',
        },
      },
      xaxis: {
        categories: data.dates,

      },

      yaxis: {
        max: 100,
      },
      legend: {
        position: 'top',
        // show :false,
        offsetY: 20,
        useSeriesColors: true,
        labels: {
          opacity: 1,

          colors: ['#15cd6a','#FF9800','#fa4f44', '#E91E63', '#6800FF', '#2E93fA','#D088CB']
        },
        markers: {
          width: 12,
          height: 12,
          strokeWidth: 0,
          opacity: 0.6,

          fillColors:['#15cd6a','#FF9800','#fa4f44', '#E91E63', '#6800FF', '#2E93fA','#D088CB'],
        },
      },
      fill: {
        opacity: 0.6,
        colors: ['#15cd6a','#FF9800','#fa4f44', '#E91E63', '#6800FF', '#2E93fA','#D088CB'],
      },

      stroke: {
        colors: ['#fff'],
        width: 1000,
      },
      dataLabels: {
        enabled: true,
        style: {
          colors: ['#666666']
        }
      },
    };

  }
  formatDate(dateInput: Date, filterType: string): string {
    const options: Intl.DateTimeFormatOptions = {};
    const date = (typeof dateInput === 'string') ? new Date(dateInput) : dateInput;

    switch(filterType) {
        case 'monthly':
            options.month = 'short';
            options.year = 'numeric';
            break;
        case 'weekly':
        case 'daily':
            options.year = 'numeric';
            options.month = 'short';
            options.day = 'numeric';
            break;
        default:
            return date.toString();
    }

    return date.toLocaleDateString('en-US', options);
  }



  chartOptionMonthGPN(
    nameGood,
    nameNeedsImprovement,
    namePoor,
    good,
    poor,
    needsImprovement,
    date,
    annotations
  ) {
    return {
      series: [
        {
          name: nameGood,
          data: good,
        },
        {
          name: nameNeedsImprovement,
          data: poor,
        },
        {
          name: namePoor,
          data: needsImprovement,
        },
      ],
      chart: {
        type: 'bar',
        height: 400,
        stacked: true,
        toolbar: {
          show: true,
          tools: {
            download: true,
          },
        },

        zoom: {
          enabled: true,
        },
      },
      responsive: [
        {
          breakpoint: 480,
          options: {
            legend: {
              position: 'bottom',
              offsetX: -10,
              offsetY: 0,
            },
          },
        },
      ],
      plotOptions: {
        bar: {
          horizontal: this.isMobile,
          columnWidth: '99%',
        },
      },
      annotations: {
        points: annotations,
      },

      dataLabels: {
        enabled: true,

        formatter: function (val) {
          return val.toFixed(1) + '%';
        },
        style: {
          fontSize: '12px',
          colors: ['#304758'],
        },
      },

      xaxis: {
        type: 'category',
        categories: date,
        tooltip: {
          x: {
            format: 'dd/MM/yy',
          },
        },
      },
      yaxis: {
        max: 100,
      },
      legend: {
        position: 'right',
        // show :false,
        offsetY: 20,
        useSeriesColors: true,
        labels: {
          opacity: 1,
          colors: ['#15cd6a', '#fba400', '#fa4f44'],
        },
        markers: {
          width: 12,
          height: 12,
          strokeWidth: 0,
          opacity: 0.6,

          fillColors: ['#0CCE6B', '#FFA400', '#FF4E42'],
        },
      },
      fill: {
        opacity: 0.6,
        colors: ['#0CCE6B', '#FFA400', '#FF4E42'],
      },
      tooltip: {
        x: {
          format: 'dd/MM/yy',
        },
      },
      stroke: {
        colors: ['#fff'],
        width: 1000,
      },
    };
  }
  chartOptionMonthGPNCompare(
    dataname1,
    dataname2,
    good1,
    good2,
    poor1,
    poor2,
    needsImprovement1,
    needsImprovement2,
    date,
  ) {
     return    {
      series: [
        {
          name: dataname1 + " good",
          group: "data1",
          data: good1
        },
        {
          name:  dataname2 + " good",
          group: "data2",
          data: good2
        },
        {
          name:  dataname1 + " average",
          group: "data1",
          data: needsImprovement1,
        },
        {
          name: dataname2 + " average",
          group: "data2",
          data: needsImprovement2,
        },
        {
          name:  dataname1 + " poor",
          group: "data1",
          data: poor1
        },

        {
          name: dataname2 + " poor",
          group: "data2",
          data: poor2
        },
      ],
      chart: {
        type: "bar",
        height: 350,
        stacked: true
      },
      stroke: {
        width: 1,
        colors: ["#fff"]
      },
      dataLabels: {
        enabled: true,

        formatter: function (val) {
          return val.toFixed(1) + '%';
        },
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: '85%',
        }
      },
      xaxis: {
        categories: date
      },
      fill: {
          opacity: 1,
          colors: ['#15cd6a', '#82df9b', '#fba400', '#ffc26b','#fa4f44','#ff907f'],
      },
      yaxis: {
        max: 100
      },
      legend: {
        position: "right",
        horizontalAlign: "left",
        offsetY: 20,
        useSeriesColors: true,
        labels: {
          opacity: 2,
          colors: ['#15cd6a', '#82df9b', '#fba400', '#ffc26b','#fa4f44','#ff907f'],
        },
        markers: {
          width: 12,
          height: 12,
          strokeWidth: 0,
          opacity: 0.6,

          fillColors: ['#15cd6a', '#82df9b', '#fba400', '#ffc26b','#fa4f44','#ff907f'],
        },
      }
    };
  }

  chartOptionCruxP75(data75, date, name, data25?, data50?, data95?) {

    let series = [];
    if(name === 'cls' ){
      series = [
        {
          name: '75th percentile',
          data: data75,
        },
      ];
    }else{
          series = [
      {
        name: '75th percentile',
        data: data75.map((d) => d / 1000),
      },
    ];
    }

    let max = 0,
      y0 = 0,
      y1 = 0,
      y2 = 0,
      y3 = 0,
      y4 = 0,
      y5 = 0;
    switch (name) {
      case 'lcp':
        max = 8;
        y1 = 2.5;
        y2 = 2.5;
        y3 = 4;
        y4 = 4;
        y5 = 8;
        break;
      case 'cls':
        max = 0.4;
        y1 = 0.1;
        y2 = 0.1;
        y3 = 0.25;
        y4 = 0.25;
        y5 = 0.4;
        break;
      case 'fcp':
        max = 5;
        y1 = 1.8;
        (y2 = 1.8), (y3 = 3);
        y4 = 3;
        y5 = 5;
        break;
      case 'inp':
        max = 0.7;
        y1 = 0.2;
        y2 = 0.2;
        y3 = 0.5;
        y4 = 0.5;
        y5 = 0.7;
        break;
      case 'ttfp':
      case 'ttfb':
        max = 4;
        y1 = 0.8;
        y2 = 0.8;
        y3 = 1.8;
        y4 = 1.8;
        y5 = 3;
        break;
    }
    name = name === "ttfp" ? "TTFB" : name.toUpperCase();
    let title = `p75 ${name} evolution`;
    if (data25 !== undefined) {
      series.push({
        name: '25th percentile',
        data: data25.map((d) => d / 1000),
      });
    }

    if (data50 !== undefined) {
      series.push({
        name: '50th percentile',
        data: data50.map((d) => d / 1000),
      });
    }
    if (data95 !== undefined) {
      series.push({
        name: '95th percentile',
        data: data95.map((d) => d / 1000),
      });
    }
    return {
      // colors: ['#000000'],
      // background: ["#ffffff"] ,

      series: series,
      chart: {
        height: 350,
        type: 'line',
      },
      dataLabels: {
        enabled: true,
      },
      stroke: {
        width: 2,
        curve: 'straight',
        // dashArray: [0, 8, 5],

        // colors: ["#340F07","#5D77D0","#9e94d4", "#dbcb51", "#db51d9"],
      },
      title: {
        text: title,
        align: 'left',
      },
      legend: {
        position: 'top',
        fontSize: '14px',
        labels: {
          opacity: 0.5,

          // colors: ["#340F07","#5D77D0","#9e94d4", "#dbcb51", "#db51d9"],
        },
        markers: {
          width: 12,
          height: 12,
          strokeWidth: 1,
          strokeColor: '#FFFFFF',

          // fillColors: ["#340F07","#5D77D0","#9e94d4", "#dbcb51", "#db51d9"],
        },
      },

      xaxis: {
        categories: date,
      },

      annotations: {
        yaxis: [
          {
            strokeDashArray: 10,
            y: y4,
            y2: y5,
            borderColor: 'black',
            fillColor: '#fa4f44',
            opacity: 0.2,
            width: '100%',
          },
          {
            y: y3,
            y2: y2,
            opacity: 0.2,
            borderColor: 'black',
            fillColor: '#fba400',
          },
          {
            strokeDashArray: 10,
            y: y0,
            y2: y1,
            borderColor: 'black',
            opacity: 0.2,
            fillColor: '#15cd6a',
          },
        ],
      },

      yaxis: {
        show: true,
        min: 0,
        max: max,
      },
      tooltip: {
        x: {
          show: true,
          format: 'MM/yy',
        },
      },
      grid: {
        show: false,
        // fillColors: ['#15cd6a', '#fba400', '#fa4f44','#340F07','F0D1CB',"#C70039","#DDEA19"],
      },
    };
  }

  chartOptionP75SubParts(timeToFirstByte_p75_Data, date, name, resourceLoadDuration_p75_Data?, resourceLoadDelay_p75_Data?, elementRenderDelay_p75_Data?) {

    let series = [
      {
        name: '75th Time To First Byte',
        data: timeToFirstByte_p75_Data.map((d) => d / 1000),
      },
      {
        name: '75th Resource Load Delay',
        data: resourceLoadDelay_p75_Data.map((d) => d / 1000),
      },
      {
        name: '75th Resource Load Duration',
        data: resourceLoadDuration_p75_Data.map((d) => d / 1000),
      },

      { name: '75th Element Render Delay',
        data: elementRenderDelay_p75_Data.map((d) => d / 1000)
      }
    ];

    let max = 8,
      y0 = 0,
      y1 = 2.5,
      y2 = 2.5,
      y3 = 4,
      y4 = 4,
      y5 = 8;

    let title = `p75 evolution`;


    return {
      // colors: ['#000000'],
      // background: ["#ffffff"] ,

      series: series,
      chart: {
        height: 350,
        type: 'line',
      },
      dataLabels: {
        enabled: true,
      },
      stroke: {
        width: 2,
        curve: 'straight',
        // dashArray: [0, 8, 5],

        // colors: ["#340F07","#5D77D0","#9e94d4", "#dbcb51", "#db51d9"],
      },
      title: {
        text: title,
        align: 'left',
      },
      legend: {
        position: 'top',
        fontSize: '14px',
        labels: {
          opacity: 0.5,

          // colors: ["#340F07","#5D77D0","#9e94d4", "#dbcb51", "#db51d9"],
        },
        markers: {
          width: 12,
          height: 12,
          strokeWidth: 1,
          strokeColor: '#FFFFFF',

          // fillColors: ["#340F07","#5D77D0","#9e94d4", "#dbcb51", "#db51d9"],
        },
      },

      xaxis: {
        categories: date,
      },

      annotations: {
        yaxis: [
          {
            strokeDashArray: 10,
            y: y4,
            y2: y5,
            borderColor: 'black',
            fillColor: '#fa4f44',
            opacity: 0.2,
            width: '100%',
          },
          {
            y: y3,
            y2: y2,
            opacity: 0.2,
            borderColor: 'black',
            fillColor: '#fba400',
          },
          {
            strokeDashArray: 10,
            y: y0,
            y2: y1,
            borderColor: 'black',
            opacity: 0.2,
            fillColor: '#15cd6a',
          },
        ],
      },

      yaxis: {
        show: true,
        min: 0,
        max: max,
        labels: {
          formatter: function(val) {
            return (Number.isInteger(val) ? val : val.toFixed(2)) + "s";
          }
        }
      },
      tooltip: {
        x: {
          show: true,
          format: 'MM/yy',
        },
      },
      grid: {
        show: false,
        // fillColors: ['#15cd6a', '#fba400', '#fa4f44','#340F07','F0D1CB',"#C70039","#DDEA19"],
      },
    };
  }

  chartOptionP75SubPartsTTFB(
    waitingDuration_p75_Data,
    dates,
    metric,
    cacheDuration_p75_Data,
    dnsDuration_p75_Data,
    connectionDuration_p75_Data,
    requestDuration_p75_Data
  ) {
    let series = [];

    // Ajout de la série pour le temps d'attente (Waiting Duration)
    if (waitingDuration_p75_Data !== undefined) {
      series.push({
        name: '75th Waiting Duration',
        data: waitingDuration_p75_Data.map((d) => d / 1000)
      });
    }

    // Ajout de la série pour le cache duration
    if (cacheDuration_p75_Data !== undefined) {
      series.push({
        name: '75th Cache Duration',
        data: cacheDuration_p75_Data.map((d) => d / 1000)
      });
    }

    // Ajout de la série pour le DNS duration
    if (dnsDuration_p75_Data !== undefined) {
      series.push({
        name: '75th DNS Duration',
        data: dnsDuration_p75_Data.map((d) => d / 1000)
      });
    }

    // Ajout de la série pour le connection duration
    if (connectionDuration_p75_Data !== undefined) {
      series.push({
        name: '75th Connection Duration',
        data: connectionDuration_p75_Data.map((d) => d / 1000)
      });
    }

    // Ajout de la série pour le request duration
    if (requestDuration_p75_Data !== undefined) {
      series.push({
        name: '75th Request Duration',
        data: requestDuration_p75_Data.map((d) => d / 1000)
      });
    }

    // Définition des valeurs pour les annotations et l'échelle de l'axe Y
    let max = 8,
        y0 = 0,
        y1 = 2.5,
        y2 = 2.5,
        y3 = 4,
        y4 = 4,
        y5 = 8;

    let title = metric ? `p75 evolution for ${metric}` : "p75 evolution";

    return {
      series: series,
      chart: {
        height: 350,
        type: 'line',
      },
      dataLabels: {
        enabled: true,
      },
      stroke: {
        width: 2,
        curve: 'straight',
      },
      title: {
        text: title,
        align: 'left',
      },
      legend: {
        position: 'top',
        fontSize: '14px',
        labels: {
          opacity: 0.5,
        },
        markers: {
          width: 12,
          height: 12,
          strokeWidth: 1,
          strokeColor: '#FFFFFF',
        },
      },
      xaxis: {
        categories: dates,
      },
      annotations: {
        yaxis: [
          {
            strokeDashArray: 10,
            y: y4,
            y2: y5,
            borderColor: 'black',
            fillColor: '#fa4f44',
            opacity: 0.2,
            width: '100%',
          },
          {
            y: y3,
            y2: y2,
            opacity: 0.2,
            borderColor: 'black',
            fillColor: '#fba400',
          },
          {
            strokeDashArray: 10,
            y: y0,
            y2: y1,
            borderColor: 'black',
            opacity: 0.2,
            fillColor: '#15cd6a',
          },
        ],
      },
      yaxis: {
        show: true,
        min: 0,
        max: max,
        labels: {
          formatter: function(val) {
            return (Number.isInteger(val) ? val : val.toFixed(2)) + "s";
          }
        }
      },
      tooltip: {
        x: {
          show: true,
          format: 'MM/yy',
        },
      },
      grid: {
        show: false,
      },
    };
  }


  chartOptionP75SubPartsLastValue(timeToFirstByte_p75,dates,metricName,resourceLoadDuration_p75,resourceLoadDelay_p75,elementRenderDelay_p75){
      return {
        series: [
          {
            name: "75th Time To First Byte",
            data: timeToFirstByte_p75
          },
          {
            name: "75th Resource Load Delay",
            data: resourceLoadDelay_p75
          },
          {
            name: "75th Resource Load Duration",
            data: resourceLoadDuration_p75
          },
          {
            name: "75th Element Render Delay",
            data: elementRenderDelay_p75
          },
        ],
        chart: {
          type: "bar",
          height: 140,

          stacked: true
        },
        plotOptions: {
          bar: {
            horizontal: true
          }
        },
        stroke: {
          width: 1,
          colors: ["#fff"]
        },
        title: {
          text: metricName ? `p75 Sub-parts for ${metricName}` : "p75 Sub-parts"
        },
        xaxis: {
          categories: dates,
          labels: {
            formatter: function(val) {
              return (Number.isInteger(val) ? val : val.toFixed(2)) + "s";
            }
          }
        },
        yaxis: {
          title: {
            text: undefined
          }
        },
        tooltip: {
          y: {
            formatter: function(val) {
              return val + "s";
            }
          }
        },
        fill: {
          opacity: 1
        },
        legend: {
          position: "right",
          horizontalAlign: "left",
          offsetX: 40
        },
        grid: {
          show: false,

        }
      };

  }

  chartOptionP75SubPartsLastValueTTFB(
    waitingDuration_p75,
    dates,
    metricName,
    cacheDuration_p75,
    dnsDuration_p75,
    connectionDuration_p75,
    requestDuration_p75
  ) {
    return {
      series: [
        {
          name: "75th Waiting Duration",
          data: waitingDuration_p75
        },
        {
          name: "75th Cache Duration",
          data: cacheDuration_p75
        },
        {
          name: "75th DNS Duration",
          data: dnsDuration_p75
        },
        {
          name: "75th Connection Duration",
          data: connectionDuration_p75
        },
        {
          name: "75th Request Duration",
          data: requestDuration_p75
        }
      ],
      chart: {
        type: "bar",
        height: 140,
        stacked: true
      },
      plotOptions: {
        bar: {
          horizontal: true
        }
      },
      stroke: {
        width: 1,
        colors: ["#fff"]
      },
      title: {
        text: metricName ? `p75 Sub-parts for ${metricName}` : "p75 Sub-parts"
      },
      xaxis: {
        categories: dates,
        labels: {
          formatter: function(val) {
            return (Number.isInteger(val) ? val : val.toFixed(2)) + "s";
          }
        }
      },
      yaxis: {
        title: {
          text: undefined
        }
      },
      tooltip: {
        y: {
          formatter: function(val) {
            return val + "s";
          }
        }
      },
      fill: {
        opacity: 1
      },
      legend: {
        position: "right",
        horizontalAlign: "left",
        offsetX: 40
      },
      grid: {
        show: false
      }
    };
  }


  chartOptionsUXROverTime(data, date, isDomain = true) {
    // console.log('hte data', data);
    const title = isDomain ? 'Domain UXR over time' : 'Page UXR over time';
    let filteredDate = [...date];
    const INPJoins = new Date('2024-01-15').getTime()
    const filteredData = [];
    data.forEach((d) => {
      if (!d.data?.[d.data?.length - 1]) {
        filteredData.push({ ...d, data: d.data.slice(0, d.data.length - 1) });
        filteredDate = [...date.slice(0, date.length - 1)];
      } else {
        filteredData.push(d);
      }
    });
    return {
      // colors: ['#000000'],
      // background: ["#ffffff"] ,

      series: filteredData,
      chart: {
        height: 350,
        type: 'line',
      },
      dataLabels: {
        enabled: true,
      },
      stroke: {
        width: 2,
        curve: 'straight',
        // dashArray: [0, 8, 5],

        // colors: ["#340F07","#5D77D0","#9e94d4", "#dbcb51", "#db51d9"],
      },
      title: {
        text: title,
        align: 'left',
      },
      legend: {
        position: 'top',
        fontSize: '14px',
        labels: {
          opacity: 0.5,

          // colors: ["#340F07","#5D77D0","#9e94d4", "#dbcb51", "#db51d9"],
        },
        markers: {
          width: 12,
          height: 12,
          strokeWidth: 1,
          strokeColor: '#FFFFFF',

          // fillColors: ["#340F07","#5D77D0","#9e94d4", "#dbcb51", "#db51d9"],
        },
      },

      xaxis: {
        categories: filteredDate,
        type: 'datetime',
        tickPlacement: 'between',
        formatter: function (value, timestamp, opts) {
          return opts.dateFormatter(new Date(timestamp)).format('MM-YYYY');
        },
      },
      annotations: {

        yaxis: [
          {
            strokeDashArray: 10,
            y: 0,
            y2: 50,
            borderColor: 'black',
            fillColor: '#fa4f44',
            opacity: 0.2,
            width: '100%',
          },
          {
            y: 50,
            y2: 90,
            opacity: 0.2,
            borderColor: 'black',
            fillColor: '#fba400',
          },
          {
            strokeDashArray: 10,
            y: 90,
            y2: 100,
            borderColor: 'black',
            opacity: 0.2,
            fillColor: '#15cd6a',
          },
        ],
      },
      tooltip: {
        x: {
          show: true,
          format: 'MM/yy',
        },
      },
      yaxis: {
        min: 0,
        max: 100,
        tickAmount: 10,
      },
      grid: {
        show: false,
        // fillColors: ['#15cd6a', '#fba400', '#fa4f44','#340F07','F0D1CB',"#C70039","#DDEA19"],
      },
    };
  }
  chartOptionsWVOverTime(data, date, isDomain = true) {
    const title = isDomain
      ? 'Domain Web Vitals status over time'
      : 'Page Web Vitals over time';

    return {
      series: data,
      chart: {
        height: 350,
        group: 'social',
        type: 'line',
      },
      dataLabels: {
        enabled: true,
      },
      stroke: {
        width: 2,
        curve: 'smooth',
      },
      title: {
        text: title,
        align: 'left',
      },
      legend: {
        position: 'top',
        fontSize: '14px',
        labels: {
          opacity: 0.5,

          // colors: ["#340F07","#5D77D0","#9e94d4", "#dbcb51", "#db51d9"],
        },
      },

      annotations: {
        yaxis: [
          {
            strokeDashArray: 10,
            y: 0,
            y2: 1,
            borderColor: 'black',
            fillColor: '#eaf4fe',
            opacity: 0.3,
            width: '100%',
          },
        ],
      },

      xaxis: {
        categories: date,
        type: 'datetime',
        tickPlacement: 'between',
        formatter: function (value, timestamp, opts) {
          return opts.dateFormatter(new Date(timestamp)).format('MM-YYYY');
        },
      },
      yaxis: {
        min: 0,
        max: 1,
        labels: {
          formatter: function (value) {
            let label; // Convertir en chaîne de caractères

            switch (value) {
              case 0:
                label = "Doesn't pass 🟥";
                return `${label}`;
              case 1:
                label = 'Passes 🟩';
                return `${label}`;
            }
          },
        },
      },
      tooltip: {
        x: {
          show: true,
          format: 'MM/yy',
        },
      },
      grid: {
        show: true,
        // fillColors: ['#15cd6a', '#fba400', '#fa4f44','#340F07','F0D1CB',"#C70039","#DDEA19"],
      },
    };
  }

  chartOptionsCompetitor(data, date, name) {
    let title = 'Good ' + name + ' over time VS competition';
    return {
      series: data,
      chart: {
        height: 350,
        type: 'line',
      },
      dataLabels: {
        enabled: false,
        style: {
          colors: [
            '#2E56FA', '#66DA26', '#546E7A', '#E91E63', '#FF9800',
            '#8E44AD', '#3498DB', '#1ABC9C', '#27AE60', '#F39C12'
          ],
        }
      },
      stroke: {
        width: 3,
        // curve: 'straight',
        colors: [
          '#2E56FA', '#66DA26', '#546E7A', '#E91E63', '#FF9800',
          '#8E44AD', '#3498DB', '#1ABC9C', '#27AE60', '#F39C12'
        ],
      },
      title: {
        text: title,
        align: 'left',
      },
      legend: {
        labels: {
          opacity: 0,
          colors: [
            '#2E56FA', '#66DA26', '#546E7A', '#E91E63', '#FF9800',
            '#8E44AD', '#3498DB', '#1ABC9C', '#27AE60', '#F39C12'
          ],
        },
        markers: {
          size: 0,
          fillColors: [
            '#2E56FA', '#66DA26', '#546E7A', '#E91E63', '#FF9800',
            '#8E44AD', '#3498DB', '#1ABC9C', '#27AE60', '#F39C12'
          ],
        },
      },

      xaxis: {
        labels: {
          trim: false,
        },
        categories: date,
      },

      tooltip: {
        y: [
          {
            title: {
              formatter: function (val) {
                return val;
              },
            },
          },
          {
            title: {
              formatter: function (val) {
                return val;
              },
            },
          },
          {
            title: {
              formatter: function (val) {
                return val;
              },
            },
          },
        ],
      },

      grid: {
        row: {
          colors: ["#f3f3f3", "transparent"], // takes an array which will be repeated on columns
          opacity: 0.5
        }
      },
    };
  }
  chartOptionsUXRCompetitor(data, date) {
    let title = 'UXR score over time';
    return {
      series: data,
      chart: {
        height: 350,
        type: 'line',
      },
      dataLabels: {
        enabled: true,
        style: {
          colors: [
            '#2E56FA', '#66DA26', '#546E7A', '#E91E63', '#FF9800',
            '#8E44AD', '#3498DB', '#1ABC9C', '#27AE60', '#F39C12'
          ],
        },
      },
      stroke: {
        width: 3,
        curve: 'straight',
        colors: [
          '#2E56FA', '#66DA26', '#546E7A', '#E91E63', '#FF9800',
          '#8E44AD', '#3498DB', '#1ABC9C', '#27AE60', '#F39C12'
        ]

      },
      title: {
        text: title,
        align: 'left',
      },
      legend: {
        labels: {
          opacity: 0,
          colors: [
            '#2E56FA', '#66DA26', '#546E7A', '#E91E63', '#FF9800',
            '#8E44AD', '#3498DB', '#1ABC9C', '#27AE60', '#F39C12'
          ]

        },
        markers: {
          size: 0,
          fillColors: [
            '#2E56FA', '#66DA26', '#546E7A', '#E91E63', '#FF9800',
            '#8E44AD', '#3498DB', '#1ABC9C', '#27AE60', '#F39C12'
          ]
       },
      },

      xaxis: {
        labels: {
          trim: true,
        },
        categories: date,
        lines: {
          show: true,
        },
      },

      yaxis: {
        lines: {
          show: true,
        },
      },

      tooltip: {
        y: [
          {
            title: {
              formatter: function (val) {
                return val;
              },
            },
          },
          {
            title: {
              formatter: function (val) {
                return val;
              },
            },
          },
          {
            title: {
              formatter: function (val) {
                return val;
              },
            },
          },
        ],
      },

        grid: {
          row: {
            colors: ["#f3f3f3", "transparent"], // takes an array which will be repeated on columns
            opacity: 0.5
          }
        },
    };
  }

  chartOptionsCompareCrux(data, legend, urlName, metricName) {
    // get the data
    const { dataGood, dataNeed, dataPoor } = data;
    let title = metricName + ' VS competition';

    return {
      series: [
        {
          name: '',
          data: [],
        },
        {
          name: legend.good,
          data: dataGood,
        },
        {
          name: legend.moderate,
          data: dataNeed,
        },
        {
          name: legend.poor,
          data: dataPoor,
        },
      ],
      chart: {
        type: 'bar',
        height: 300,
        stacked: true,
        // stackType: "100%"
      },
      plotOptions: {
        bar: {
          horizontal: true,
        },
      },
      stroke: {
        width: 0,
        colors: [],
      },
      title: {
        text: title,
      },
      xaxis: {
        categories: urlName,
      },
      yaxis: {
        max: 100,
      },
      tooltip: {
        y: {
          formatter: function (val) {
            return val + '%';
          },
        },
      },
      fill: {
        opacity: 1,
      },
      legend: {
        position: 'top',
        horizontalAlign: 'left',
        offsetX: 40,

        markers: {
          fillColors: ['#ffff', '#43b399', '#f6ad45', '#ef6f52'],
        },
      },
      dataLabels: {
        enabled: true,
        style: {
          colors: ['#666666']
        }
      }

    };
  }

  chartOptionsComparePSI(metric, legend, urlName, metricName) {
    const dataGood = this.getArrayTypeDatas(metric, 'good');
    const dataNeed = this.getArrayTypeDatas(metric, 'needsImprovement');
    const dataPoor = this.getArrayTypeDatas(metric, 'poor');

    let title = metricName + ' VS competition';

    return {
      series: [
        {
          name: '',
          data: [],
        },
        {
          name: legend.good,
          data: dataGood,
        },
        {
          name: legend.moderate,
          data: dataNeed,
        },
        {
          name: legend.poor,
          data: dataPoor,
        },
      ],
      chart: {
        type: 'bar',
        height: 300,
        stacked: true,
        // stackType: "100%"
      },
      plotOptions: {
        bar: {
          horizontal: true,
        },
      },
      stroke: {
        width: 1,
        colors: ['#15cd6a', '#fba400', '#fa4f44'],
      },
      title: {
        text: title,
      },
      xaxis: {
        categories: urlName,
      },
      yaxis: {
        max: 100,
      },
      tooltip: {
        y: {
          formatter: function (val) {
            return val + '%';
          },
        },
      },
      fill: {
        opacity: 1,
      },
      legend: {
        position: 'top',
        horizontalAlign: 'left',
        offsetX: 40,

        markers: {
          fillColors: ['#ffff', '#43b399', '#f6ad45', '#ef6f52'],
        },
      },
    };
  }

  chartOptionsComparePSIOver(metric, legend) {
    const dataGood = this.getArrayTypeDatas(metric, 'good');
    const dataNeed = this.getArrayTypeDatas(metric, 'need');
    const dataPoor = this.getArrayTypeDatas(metric, 'poor');

    let typetableau = ['Mobile', 'Desktop'];

    return {
      series: [
        {
          name: legend.good,
          data: dataGood,
        },
        {
          name: legend.moderate,
          data: dataNeed,
        },
        {
          name: legend.poor,
          data: dataPoor,
        },
      ],
      chart: {
        type: 'bar',
        height: 300,
        stacked: true,
        // stackType: "100%"
      },
      plotOptions: {
        bar: {
          horizontal: true,
        },
      },
      stroke: {
        width: 1,
        colors: ['#15cd6a', '#fba400', '#fa4f44'],
      },
      xaxis: {
        categories: typetableau,
      },
      yaxis: {
        max: 100,
      },
      tooltip: {
        y: {
          formatter: function (val) {
            return val + '%';
          },
        },
      },
      fill: {
        opacity: 1,
        colors: ['#15cd6a', '#fba400', '#fa4f44'],
      },
      legend: {
        show: false,
      },
    };
  }

  getArrayTypeDatas(array: Array<TypeData>, name: string) {
    //return un tableau d'un attribut(good, need, poor) de TypeData passé en parametre
    let newArray: Array<any> = [];
    name = name.toLowerCase() === 'need' ? 'needsImprovement' : name;
    array.map((data) => {
      if (name.toLowerCase() !== 'needsImprovement'.toLowerCase())
        newArray.push(data[name.toLowerCase()]);
      else newArray.push(data.needsImprovement);
    });
    return newArray;
  }

  getChartOptionsLcpBreakdown(data: any): any {
    const lcpImageMetrics = data.date && data.date.length > 0
      ? {
          p75LcpImageRenderDelay: data.p75LcpImageRenderDelay?.[0],
          p75LcpImageLoadDelay: data.p75LcpImageLoadDelay?.[0],
          p75LcpImageLoadDuration: data.p75LcpImageLoadDuration?.[0],
          p75LcpImageTtfb: data.p75LcpImageTtfb?.[0],
        }
      : {
          p75LcpImageRenderDelay: 0,
          p75LcpImageLoadDelay: 0,
          p75LcpImageLoadDuration: 0,
          p75LcpImageTtfb: 0,
        };

    return {
      series: [
        {
          name: 'Time to First Byte',
          data: [lcpImageMetrics.p75LcpImageTtfb],
        },
        {
          name: 'Resource Load Delay',
          data: [lcpImageMetrics.p75LcpImageLoadDelay],
        },
        {
          name: 'Resource Load Duration',
          data: [lcpImageMetrics.p75LcpImageLoadDuration],
        },
        {
          name: 'Element Render Delay',
          data: [lcpImageMetrics.p75LcpImageRenderDelay],
        },
      ],
      chart: {
        type: 'bar',
        height: 250,
        stacked: true,
        toolbar: { show: false },
        sparkline: { enabled: false },
      },
      plotOptions: {
        bar: {
          horizontal: true,
          barHeight: '40%',
        },
      },
      grid: {
        show: true,
        padding: { left: 0, right: 0 },
      },
      dataLabels: {
        enabled: true,
        formatter: (val: number) => val.toFixed(0) + ' ms',
      },
      xaxis: {
        title: { text: 'Milliseconds (ms)' },
      },
      title: {
        text: 'LCP Image Timing Breakdown (P75)',
        align: 'center',
        style: { fontSize: '14px' },
      },
      colors: ['#00E396', '#008FFB', '#FEB019', '#FF4560'],
      tooltip: {
        y: {
          formatter: (val: number) => val.toFixed(0) + ' ms',
        },
      },
      legend: {
        position: 'bottom',
        horizontalAlign: 'center',
      },
    };
  }

    /**
 * Returns an ApexCharts configuration object for a "funnel-like" horizontal bar chart.
 *
 * @param seriesValues - An array of numbers representing the data for each step in the funnel
 * @param categoriesLabels - An array of strings for labeling each data point/category
 * @returns An ApexCharts options object
 */
getFunnelChartOptions(
  seriesValues: number[],
  categoriesLabels: string[]
): any {
  return {
    // Each "series" represents a set of data on the chart
    series: [
      {
        name: "UXR",       // A label for your series
        data: seriesValues // Array of numeric values to be plotted
      },
    ],
    chart: {
      // Specify the type of chart and its height in pixels
      type: "bar",
      height: 350,
    },
    plotOptions: {
      bar: {
        // Bar styling options
        borderRadius: 0,
        horizontal: true,  // Make bars horizontal (left-to-right)
        barHeight: "50%",  // Height of each bar (relative to the total)

        // This property is not officially documented in ApexCharts,
        // but used by some to emulate a funnel appearance
        isFunnel: true,
      },
    },
    // Data labels on each bar, showing text and values
    dataLabels: {
      enabled: true,
      style: {
        // Set text color for the data labels
        colors: ["#2e2e2e"],
      },
      // Format the label to display "category: value%"
      formatter: (val, opt) => {
        return opt.w.globals.labels[opt.dataPointIndex] + ": " + val + "%";
      },
      dropShadow: {
        enabled: false,
      },
    },
    // Title configuration
    title: {
      text: "UXR funnel",    // Chart title
      align: "center",       // Align the title in the center
    },
    // Horizontal axis (X-axis) configuration
    xaxis: {
      categories: categoriesLabels, // Labels for each data point
      labels: {
        style: {
          colors: "#000000",  // X-axis labels color
        },
      },
    },
    // Vertical axis (Y-axis) configuration
    yaxis: {
      labels: {
        style: {
          colors: "#000000",  // Y-axis labels color
        },
      },
    },
    // Legend configuration
    legend: {
      show: false,             // Hide the legend (set to true to show it)
      labels: {
        colors: "#000000",     // Legend text color (used if show: true)
      },
    },
    // Colors used for the bars (single color in this example)
    colors: ["#008FFB"],
  };
}
}

