import { HttpClient, HttpHeaders } from '@angular/common/http';
import { withCache, CacheBucket, HttpCacheManager } from '@ngneat/cashew';
import { Injectable } from '@angular/core';
import { ConstantService } from 'src/app/shared/constant/constant.service';
import { tap } from 'rxjs/operators';
import {
  DataLcpFidClsTtfb,
  InternalPageDetail,
  navTypeData,
} from 'src/app/shared/models/user.models';
import { Observable } from 'rxjs';
import { OverviewApi } from 'src/app/shared/models/pagespeed.model';
import { MatRadioChange } from '@angular/material/radio';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';

interface dataScreenshoot {
  url: string;
  title: string;
  finalScreenshoot: string;
}

@Injectable({
  providedIn: 'root',
})
export class SpeetalsDatabaseService {
  auth_token: any;
  apiRoutes: any;
  urlAudit: string;
  arrayScreenshoot: Array<dataScreenshoot> = [];
  listWebsites = new CacheBucket();
  constructor(
    private httpClient: HttpClient,
    private manager: HttpCacheManager,
    private router: Router,
  ) {
    this.apiRoutes = ConstantService.apiRoute;
    this.ngOnInit();
    this.auth_token = localStorage.getItem(
      ConstantService.localStorageKeys.access_Token,
    );
  }

  async ngOnInit() {
    if (this.router.url.slice(0, 5) === '/demo') {
      localStorage.setItem(
        ConstantService.loadingUrl.Url,
        'https://www.amazon.com',
      );
      localStorage.setItem(ConstantService.loadingUrl.device, 'mobile');
    }
  }
  /**
   * This is used to set data in database
   */

  getDataListpage(userId, device) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${this.getToken()}`,
    });
    return this.httpClient
      .get(
        this.apiRoutes.speetalsApi + '/listwebsite/' + userId + '/' + device,
        { headers: headers, context: withCache({ bucket: this.listWebsites }) },
      )
      .pipe(
        tap((data) => {
          if (userId !== 'demo') {
          }
          // userLoggedNbWebsites
        }),
      );
  }


  getMostRecentDocByUrlAndUser(projectId: string, url: string) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${this.getToken()}`,
    });

    const endpoint = `${this.apiRoutes.speetalsApi}/crux-by-user-url?projectId=${projectId}&url=${encodeURIComponent(url)}`;


    return this.httpClient.get(endpoint, { headers });
  }
  getDataListpageShared(userId, device) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${this.getToken()}`,
    });
    return this.httpClient.get(
      this.apiRoutes.speetalsApi +
        '/listwebsite/sharedWith/' +
        userId +
        '/' +
        device,
      { headers: headers, context: withCache() },
    );
  }

  getDatainternalUrl(userId, projectId, device, diffDate = '') {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${this.getToken()}`,
    });

    return this.httpClient.get(
      this.apiRoutes.speetalsApi +
        '/internalpages/' +
        userId +
        '/' +
        projectId +
        '/' +
        device +
        (diffDate ? `?diff_date=${diffDate}` : ''),
      { headers: headers },
    );
  }

  getDemoDatainternalUrl(device) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });

    return this.httpClient.get(
      this.apiRoutes.speetalsApi + '/demointernalpages/' + device,
      { headers: headers, context: withCache() },
    );
  }
  getDataOverviewAll(userId, url): Observable<OverviewApi> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${this.getToken()}`,
    });

    return this.httpClient.get<OverviewApi>(
      this.apiRoutes.speetalsApi + '/overview/' + userId + '/' + url,
      { headers: headers, context: withCache() },
    );
  }

  getDataOverview(userId, url, device): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${this.getToken()}`,
    });

    return this.httpClient.get<OverviewApi>(
      this.apiRoutes.speetalsApi +
        '/overview/' +
        userId +
        '/' +
        url +
        '/' +
        device,
      { headers: headers, context: withCache() },
    );
  }
  getDataByMetric(
    userId,
    id,
    device,
    metric: string,
  ): Observable<Array<DataLcpFidClsTtfb>> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${this.getToken()}`,
    });
    return this.httpClient.get<Array<DataLcpFidClsTtfb>>(
      this.apiRoutes.speetalsApi +
        '/' +
        metric +
        '/' +
        userId +
        '/' +
        id +
        '/' +
        device,
      { headers: headers },
    );
  }

  getDemoDataByMetric(metric: string, device: string) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    return this.httpClient.get<Array<DataLcpFidClsTtfb>>(
      this.apiRoutes.speetalsApi +
        '/demo-project-by-metric/' +
        metric +
        '/' +
        device,
      { headers: headers, context: withCache() },
    );
  }

  getDataTtfp(userId, projectId, device): Observable<Array<DataLcpFidClsTtfb>> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${this.getToken()}`,
    });
    return this.httpClient.get<Array<DataLcpFidClsTtfb>>(
      this.apiRoutes.speetalsApi +
        '/ttfp/' +
        userId +
        '/' +
        projectId +
        '/' +
        device,
      { headers: headers, context: withCache() },
    );
  }

  getDemoDataOverviewAll(): Observable<OverviewApi> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    console.log('overview all');
    return this.httpClient.get<OverviewApi>(
      this.apiRoutes.speetalsApi + '/demo-project-overview-all',
      { headers: headers, context: withCache() },
    );
  }

  getDemodataOverview(device: string = 'desktop'): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    return this.httpClient.get<OverviewApi>(
      this.apiRoutes.speetalsApi + '/demo-project-overview/' + device,
      { headers: headers, context: withCache() },
    );
  }

  getBarUrl(userId) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${this.getToken()}`,
    });
    return this.httpClient.get(
      this.apiRoutes.speetalsApi + '/barurl/' + userId,
      { headers: headers },
    );
  }

  getCompetitor(userId, projectId, device) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${this.getToken()}`,
    });
    return this.httpClient.get(
      this.apiRoutes.speetalsApi +
        '/competitor/' +
        userId +
        '/' +
        projectId +
        '/' +
        device,
      { headers: headers, context: withCache() },
    );
  }

  getCompetitors(server, userId, projectId, device) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${this.getToken()}`,
    });
    return this.httpClient.get(
      this.apiRoutes.speetalsApi +
        '/competitor/' +
        server +
        '/' +
        userId +
        '/' +
        projectId +
        '/' +
        device,
      { headers: headers },
    );
  }

  getDemoCompetitors(server, device) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    return this.httpClient.get(
      this.apiRoutes.speetalsApi + '/competitor/' + server + '/demo/' + device,
      { headers: headers, context: withCache() },
    );
  }

  getInternalPageDetail(
    userId,
    projectId,
    name,
    device,
    type?: string,
    diff_date = 'most_recent',
  ): Observable<InternalPageDetail> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${this.getToken()}`,
    });

    const madeUrl =
      this.apiRoutes.speetalsApi +
      '/internalpagedetail/' +
      userId +
      '/' +
      projectId +
      '/' +
      name +
      '/' +
      device +
      `${type ? '?' + type : ''}${
        diff_date ? (!type ? '?' : '&') + 'diff_date=' + diff_date : ''
      }`;

    return this.httpClient.get<InternalPageDetail>(madeUrl, {
      headers: headers,
    });
  }

  getDemoInternalPageDetail(device, url): Observable<InternalPageDetail> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${this.getToken()}`,
    });
    return this.httpClient.get<InternalPageDetail>(
      this.apiRoutes.speetalsApi +
        '/demointernalpagedetail/' +
        device +
        '/' +
        url,
      { headers: headers, context: withCache() },
    );
  }
  getAudits(projectId, userId, url, device): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${this.getToken()}`,
    });

    return this.httpClient.get<any>(
      this.apiRoutes.speetalsApi +
        '/audits/' +
        projectId +
        '/' +
        url +
        '/' +
        device,
      { headers: headers },
    );
  }

  getDemoAudits(url, device): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${this.getToken()}`,
    });
    return this.httpClient.get<any>(
      this.apiRoutes.speetalsApi + '/audits-demo/' + url + '/' + device,
      { headers: headers, context: withCache() },
    );
  }

  geturlinfo(url) {
    const x = this.arrayScreenshoot.find((h) => h.url === url)!;
    return x;
  }

  getArrayScreenshoot(array: any) {
    array.map((data: any) => {
      let url: string = data.url;
      let title: string = data.title;
      let finalScreenshoot: string = data.finalScreenshoot;
      let c: dataScreenshoot = { url, title, finalScreenshoot };
      if (this.arrayScreenshoot.length > 0) {
        if (this.arrayScreenshoot.find((h) => h.url === c.url) === undefined) {
          this.arrayScreenshoot.push(c);
        }
      } else {
        this.arrayScreenshoot.push(c);
      }
    });
    return this.arrayScreenshoot;
  }
  getScreenShot(data, url): string {
    let image: string;
    data.map((element: { url: string; finalScreenshot: string }) => {
      if (url === element.url) {
        image = element.finalScreenshot;
      }
    });
    return image;
  }

  setDevice(event: MatRadioChange, device) {
    localStorage.setItem(ConstantService.loadingUrl.device, event.value);
    const deviceValue = event.value;
    device = deviceValue;
  }
  getlink(param) {
    let home = 'home/page';
    let page = 'internalpages';
    return '/' + home + '/' + param + '/' + page;
  }

  passOrNot(overall: string): boolean {
    if (overall !== undefined) return true;
    return false;
  }

  invalidateListwebistes() {
    this.manager.delete(this.listWebsites);
  }

  getToken() {
    return localStorage.getItem(ConstantService.localStorageKeys.access_Token);
  }

  getNavTypeData(
    userId,
    id,
    device,
  ): Observable<Array<navTypeData>> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${this.getToken()}`,
    });
    return this.httpClient.get<Array<navTypeData>>(
      this.apiRoutes.speetalsApi +
        '/' +
        'navtype' +
        '/' +
        userId +
        '/' +
        id +
        '/' +
        device,
      { headers: headers },
    );
  }
}
