import { Component, EventEmitter, Input, Output, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { CountryService, Country } from '../../services/country.service';

@Component({
  selector: 'app-mat-select-country',
  standalone: true,
  imports: [CommonModule, MatFormFieldModule, MatSelectModule, FormsModule, ReactiveFormsModule, MatInputModule],
  template: `
    <mat-form-field [appearance]="appearance" [style.width.%]="100">
      <mat-label>{{ label }}</mat-label>
      <mat-select 
        [disabled]="disabled"
        [(ngModel)]="selectedCountry" 
        (selectionChange)="onSelectionChange()">
        <div class="search-container">
          <mat-form-field appearance="outline" class="search-field">
            <mat-label>Search</mat-label>
            <input 
              matInput 
              [(ngModel)]="searchText" 
              (keyup)="filterCountries()"
              placeholder="Search country"
              [ngModelOptions]="{standalone: true}">
          </mat-form-field>
        </div>
        <mat-option *ngFor="let country of filteredCountries" [value]="country.code">
          <img 
            *ngIf="showFlags" 
            class="country-flag" 
            src="assets/svg-country-flags/svg/{{country.code.toLowerCase()}}.svg" 
            alt="{{country.name}} flag"
            onerror="this.style.display='none'"
          />
          {{ country.name }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  `,
  styles: [`
    .country-flag {
      width: 20px;
      height: 15px;
      margin-right: 8px;
      vertical-align: middle;
      object-fit: cover;
      border: 1px solid #eee;
    }
    .search-container {
      position: sticky;
      top: 0;
      z-index: 1;
      background-color: white;
      padding: 8px 16px;
      margin-bottom: 8px;
    }
    .search-field {
      width: 100%;
    }
  `]
})
export class MatSelectCountryComponent implements OnInit {
  @Input() appearance: 'outline' | 'fill' | 'standard' = 'outline';
  @Input() label: string = 'Country';
  @Input() disabled: boolean = false;
  @Input() autocomplete: boolean = false;
  @Input() showFlags: boolean = true;
  @Input() value: string = '';
  @Output() onCountrySelected = new EventEmitter<any>();

  countries: Country[] = [];
  filteredCountries: Country[] = [];
  selectedCountry: string = '';
  searchText: string = '';

  constructor(private countryService: CountryService) {}

  ngOnInit() {
    this.countryService.getCountries().subscribe(countries => {
      this.countries = countries;
      this.filteredCountries = [...countries];
      
      if (this.value) {
        this.selectedCountry = this.value;
      }
    });
  }

  filterCountries() {
    if (!this.searchText) {
      this.filteredCountries = [...this.countries];
    } else {
      const searchTextLower = this.searchText.toLowerCase();
      this.filteredCountries = this.countries.filter(country => 
        country.name.toLowerCase().includes(searchTextLower)
      );
    }
  }

  onSelectionChange() {
    const selectedCountryObj = this.countries.find(c => c.code === this.selectedCountry);
    this.onCountrySelected.emit(selectedCountryObj);
  }
} 