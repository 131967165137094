// src/app/services/user-helper.service.ts
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class UserHelperService {
  private apiUrl = `${environment.apiUrl}`;

  constructor(private http: HttpClient) { }

  sendHelpRequest(payload: any): Observable<any> {
    // Envoyer en JSON
    return this.http.post(`${this.apiUrl}/help-reqs`, payload, {
      headers: { 'Content-Type': 'application/json' },
    });
  }
}

