<div class="my-3">
  <div class="d-flex justify-content-center align-items-center" id="gsi_button"></div>
</div>
@if (loading) {
<div class="spinner-border" role="status">
  <span class="sr-only"></span>
</div>
}
<!-- <script src="https://apis.google.com/js/platform.js?onload=onLoadCallback" async defer></script>
<script>
  window.onLoadCallback = function(){
  gapi.auth2.init({
  client_id: '791751696191-oeb3hegu5f2n43so1j5r93fh5mn42eai.apps.googleusercontent.com'
  });
  } -->
<!-- </script> -->
