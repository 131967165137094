import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of, catchError } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';

export interface Country {
  name: string;
  code: string;
  flag?: string;
}

// Fallback country data in case the JSON file can't be loaded
const FALLBACK_COUNTRIES: Country[] = [
  { name: 'United States', code: 'US' },
  { name: 'United Kingdom', code: 'GB' },
  { name: 'France', code: 'FR' },
  { name: 'Germany', code: 'DE' },
  { name: 'Spain', code: 'ES' },
  { name: 'Italy', code: 'IT' },
  { name: 'Canada', code: 'CA' },
  { name: 'Australia', code: 'AU' },
  { name: 'Japan', code: 'JP' },
  { name: 'China', code: 'CN' },
  { name: 'India', code: 'IN' },
  { name: 'Brazil', code: 'BR' },
  // Add more common countries as needed
];

@Injectable({
  providedIn: 'root'
})
export class CountryService {
  private countries$: Observable<Country[]>;

  constructor(private http: HttpClient) {
    this.countries$ = this.http.get<Country[]>('assets/data/countries.json').pipe(
      catchError(() => {
        console.warn('Could not load countries.json, using fallback data');
        return of(FALLBACK_COUNTRIES);
      }),
      shareReplay(1)
    );
  }

  getCountries(): Observable<Country[]> {
    return this.countries$;
  }

  getCountryByCode(code: string): Observable<Country | undefined> {
    return this.countries$.pipe(
      map(countries => countries.find(country => country.code === code))
    );
  }
} 