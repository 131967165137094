import { Component, EventEmitter, Input, Output, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { MatChipsModule } from '@angular/material/chips';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { FormsModule } from '@angular/forms';
import { CountryService, Country } from '../../services/country.service';

@Component({
  selector: 'app-multi-country-selector',
  standalone: true,
  imports: [CommonModule, MatFormFieldModule, MatSelectModule, FormsModule, MatChipsModule, MatIconModule, MatInputModule],
  template: `
    <div class="multi-country-container">
      <mat-form-field [appearance]="appearance" [style.width.%]="100">
        <mat-label>{{ label }}</mat-label>
        <mat-select 
          [disabled]="disabled || selectedCountriesObjects.length >= maxSelections"
          [(ngModel)]="currentSelection" 
          (selectionChange)="onSelectionChange()">
          <div class="search-container">
            <mat-form-field appearance="outline" class="search-field">
              <mat-label>Search</mat-label>
              <input 
                matInput 
                [(ngModel)]="searchText" 
                (keyup)="filterCountries()"
                placeholder="Search country"
                [ngModelOptions]="{standalone: true}">
            </mat-form-field>
          </div>
          <mat-option *ngFor="let country of filteredAvailableCountries" [value]="country">
            <img 
              *ngIf="showFlags" 
              class="country-flag" 
              src="assets/svg-country-flags/svg/{{country.code.toLowerCase()}}.svg" 
              alt="{{country.name}} flag"
              onerror="this.style.display='none'"
            />
            {{ country.name }}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <div class="selected-countries">
        <div *ngFor="let country of selectedCountriesObjects" class="country-chip">
          <img 
            *ngIf="showFlags" 
            class="country-flag-chip" 
            src="assets/svg-country-flags/svg/{{country.code.toLowerCase()}}.svg" 
            alt="flag"
            onerror="this.style.display='none'"
          />
          {{ country.name }}
          <button class="remove-button" (click)="removeCountry(country)">
            <mat-icon>close</mat-icon>
          </button>
        </div>
      </div>
    </div>
  `,
  styles: [`
    .multi-country-container {
      display: flex;
      flex-direction: column;
      width: 100%;
    }
    .country-flag {
      width: 20px;
      height: 15px;
      margin-right: 8px;
      vertical-align: middle;
      object-fit: cover;
      border: 1px solid #eee;
    }
    .selected-countries {
      display: flex;
      flex-wrap: wrap;
      gap: 8px;
      margin-top: 8px;
    }
    .country-chip {
      display: flex;
      align-items: center;
      background-color: #f0f0f0;
      border-radius: 16px;
      padding: 4px 8px;
      font-size: 14px;
    }
    .country-flag-chip {
      width: 16px;
      height: 12px;
      margin-right: 6px;
      object-fit: cover;
      border: 1px solid #eee;
    }
    .remove-button {
      display: flex;
      align-items: center;
      justify-content: center;
      background: none;
      border: none;
      cursor: pointer;
      margin-left: 4px;
      padding: 0;
      height: 18px;
      width: 18px;
    }
    .remove-button mat-icon {
      font-size: 16px;
      height: 16px;
      width: 16px;
      line-height: 16px;
    }
    .search-container {
      position: sticky;
      top: 0;
      z-index: 1;
      background-color: white;
      padding: 8px 16px;
      margin-bottom: 8px;
    }
    .search-field {
      width: 100%;
    }
  `]
})
export class MultiCountrySelectorComponent implements OnInit {
  @Input() appearance: 'outline' | 'fill' | 'standard' = 'outline';
  @Input() label: string = 'Select Countries';
  @Input() disabled: boolean = false;
  @Input() showFlags: boolean = true;
  @Input() maxSelections: number = 5;
  @Input() set initialSelections(values: string[]) {
    if (values && values.length) {
      // Convert country names to country objects if needed
      this.countryService.getCountries().subscribe(countries => {
        this.selectedCountriesObjects = values.map(name => {
          const country = countries.find(c => c.name === name);
          return country || { name: name, code: 'unknown' };
        });
        this.emitCountryNames();
      });
    }
  }
  
  @Output() selectionChange = new EventEmitter<string[]>();

  countries: Country[] = [];
  selectedCountriesObjects: Country[] = [];
  currentSelection: Country;
  searchText: string = '';
  
  get availableCountries(): Country[] {
    return this.countries.filter(country => 
      !this.selectedCountriesObjects.some(selected => selected.code === country.code)
    );
  }
  
  get filteredAvailableCountries(): Country[] {
    if (!this.searchText) {
      return this.availableCountries;
    }
    
    const searchTextLower = this.searchText.toLowerCase();
    return this.availableCountries.filter(country => 
      country.name.toLowerCase().includes(searchTextLower)
    );
  }

  constructor(private countryService: CountryService) {}

  ngOnInit() {
    this.countryService.getCountries().subscribe(countries => {
      this.countries = countries;
    });
  }

  filterCountries() {
    // The filtering is handled by the filteredAvailableCountries getter
    // This method is here to be called on keyup events
  }

  onSelectionChange() {
    if (this.currentSelection && 
        !this.selectedCountriesObjects.some(c => c.code === this.currentSelection.code)) {
      if (this.selectedCountriesObjects.length < this.maxSelections) {
        this.selectedCountriesObjects.push(this.currentSelection);
        this.emitCountryNames();
        this.currentSelection = null;
        this.searchText = '';
      }
    }
  }

  removeCountry(country: Country) {
    this.selectedCountriesObjects = this.selectedCountriesObjects.filter(
      c => c.code !== country.code
    );
    this.emitCountryNames();
  }

  private emitCountryNames() {
    // Emit only the country names
    const countryNames = this.selectedCountriesObjects.map(country => country.name);
    this.selectionChange.emit(countryNames);
  }
} 