// show-details-workflow.component.ts
import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ChartOptionsService } from 'src/app/services/common/chart/chart-options.service';

interface MetricData {
  good: number;
  need: number;
  poor: number;
  p75: number;
}

@Component({
    selector: 'app-show-details-workflow',
    templateUrl: './show-details-workflow.component.html',
    standalone: false
})
export class ShowDetailsWorkflowComponent implements OnInit {
  metrics: Array<{
    name: string;
    chartOptions: any;
  }> = [];

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: {
      url: string,
      device: string,
      lcpData: MetricData,
      clsData: MetricData,
      inpData: MetricData
    },
    private chartOptionsService : ChartOptionsService,
  ) {}

  ngOnInit(): void {
    const rawMetrics = [
      { name: 'LCP', data: this.data.lcpData },
      { name: 'CLS', data: this.data.clsData },
      { name: 'INP', data: this.data.inpData },
    ];
    this.metrics = rawMetrics.map(m => {
      const chartOpts = this.chartOptionsService.chartOptionsOverview({
        good: m.data.good,
        need: m.data.need,
        poor: m.data.poor,
      });
      return { name: m.name, chartOptions: chartOpts, p75: m.data.p75 };
    });
  }
}
