<h2 mat-dialog-title>Details for {{ data.url }} ({{ data.device }})</h2>

<div mat-dialog-content class="d-flex flex-column flex-lg-row justify-content-center align-items-center">

  <!-- Boucle sur chaque métrique -->
  <div *ngFor="let m of metrics" class="mb-4">
    <h3>{{ m.name }} distribution</h3>
    <div class="d-flex justify-content-center align-items-center mt-3 text-muted small">
      <span>
        75th percentile:
        <!-- Si la métrique est CLS, on divise par 100 sauf si p75 vaut 0 -->
        {{ m.name === 'CLS'
            ? (m.p75 > 0 ? (m.p75 / 100) : 0)
            : m.p75
        }}
        <!-- Ajout du suffixe "ms" sauf pour CLS -->
        {{ m.name === 'CLS' ? '' : 'ms' }}
      </span>
    <mat-icon
      style="font-size: 18px; opacity: 0.7; margin-left: 4px;"
      [matTooltip]="'The performance value that 75% of users experience or lower. Lower is better.'"
      matTooltipPosition="left">
      help
    </mat-icon>
  </div>
    <apx-chart
      [series]="m.chartOptions.series"
      [chart]="m.chartOptions.chart"
      [plotOptions]="m.chartOptions.plotOptions"
      [dataLabels]="m.chartOptions.dataLabels"
      [colors]="m.chartOptions.colors"
      [legend]="m.chartOptions.legend"
      [title]="m.chartOptions.title"
      [xaxis]="m.chartOptions.xaxis">
    </apx-chart>
  </div>

</div>
