
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class DataService {
  private sharedData: any;
  private toggleState = new BehaviorSubject<any>(null);

  setSharedData(data: any) {
    this.sharedData = data;
    localStorage.setItem('sharedData', JSON.stringify(data));
  }

  getSharedData() {
    if (!this.sharedData) {
      const stored = localStorage.getItem('sharedData');
      if (stored) {
        this.sharedData = JSON.parse(stored);
      }
    }
    return this.sharedData;
  }

  toggleState$ = this.toggleState.asObservable();

  changeToggleState(data: any) {
    localStorage.setItem('toggleState', JSON.stringify(data));
    this.toggleState.next(data);
  }
}
