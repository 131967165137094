<form class="form" [formGroup]="form" (ngSubmit)="login()">
  <mat-form-field class="form__input" appearance="outline">
    <input
      matInput
      placeholder="Email Address"
      type="email"
      formControlName="email"
    />
  </mat-form-field>

  <mat-form-field class="form__input" appearance="outline">
    <input
      matInput
      placeholder="Password"
      type="password"
      formControlName="password"
    />
  </mat-form-field>

  <div class="form-actions">
    <button
      class="form-actions__login btn btn-success"
      type="submit"
    >
      Login
    </button>
    <button class="form-actions__forget btn btn-light" >Forgot password?</button>
  </div>
</form>
