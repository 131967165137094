import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class NavigationWorkflowService {


  private baseUrl = environment.apiUrl;

  constructor(private http: HttpClient) { }

  createNavigationWorkflow(payload: any): Observable<any> {
    return this.http.post(`${this.baseUrl}/navigation-workflows`, payload);
  }

  getNavigationWorkflows(projectId: string): Observable<any> {
    return this.http.get(`${this.baseUrl}/navigation-workflows?filter[where][projectId]=${projectId}`);
  }

  getNavigationWorkflowById(workflowId: string): Observable<any> {
    return this.http.get(`${this.baseUrl}/navigation-workflows/${workflowId}`);
  }

  deleteNavigationWorkflow(workflowId: string) {
    return this.http.delete<any>(`${this.baseUrl}/navigation-workflows/${workflowId}`);
  }

}
