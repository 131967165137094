import { HttpClient, HttpHeaders } from '@angular/common/http';
import { withCache } from '@ngneat/cashew';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { ConstantService } from 'src/app/shared/constant/constant.service';
import { environment } from '../../../../environments/environment';
import {
  AllPlan,
  Plan,
  UserIdentity,
} from '../../../shared/models/user.models';
import { IntegrationSlack } from 'src/app/shared/models/integrate-slack.model';
import { Scaffolding } from 'src/app/shared/models/Scaffolding-alerts.model';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  Scaffolding: object;
  auth_token: string;
  public loading$ = new BehaviorSubject<boolean>(true);
  IntegrationSlack: Object;
  userId: string;
  constructor(private httpClient: HttpClient) {
    this.auth_token = localStorage.getItem(
      ConstantService.localStorageKeys.access_Token,
    );
    this.userId = localStorage.getItem(ConstantService.localStorageKeys.userId);
  }

  public getUserIdentity(userId: string): Observable<UserIdentity> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${this.auth_token}`,
    });
    return this.httpClient.get<UserIdentity>(
      `${environment.apiUrl}/user-identities/${userId}`,
      { headers: headers, context: withCache() },
    );
  }

  public getUserDetail(userId: string): Observable<any> {
    this.auth_token = localStorage.getItem(
      ConstantService.localStorageKeys.access_Token,
    );

    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${this.auth_token}`,
    });
    return this.httpClient.get<UserIdentity>(
      `${environment.apiUrl}/auth/get-user-detail/${userId}`,
      { headers: headers },
    );
  }

  public getCurrentUser(userId: string): Observable<any> {
    this.auth_token = localStorage.getItem(
      ConstantService.localStorageKeys.access_Token,
    );

    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${this.auth_token}`,
    });
    return this.httpClient.get<UserIdentity>(
      `${environment.apiUrl}/auth/user/${userId}`,
      { headers: headers },
    );
  }

  getOffersByUserId(userId: string): Observable<any[]> {

    return this.httpClient.get<any[]>(
      `${environment.apiUrl}/req-offers/user/${userId}`,
    );
  }

  rejectOffer(offerId: string, cancellationReason: string): Observable<any> {

    return this.httpClient.patch(`${environment.apiUrl}/req-offers/${offerId}/${cancellationReason}`, { active: false, status: 'rejected' });
  }
  public updateOfferStatus(offerId: string, status: string): Observable<any> {
    return this.httpClient.patch(`${environment.apiUrl}/req-offers/${offerId}`, { status: status });

  }

  public integrateSlack(payload: object): Observable<IntegrationSlack> {
    this.loading$.next(true);
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    return this.httpClient.post<IntegrationSlack>(
      `${environment.apiUrl}/integrate-slacks`,
      JSON.stringify(payload),
      { headers },
    );
  }

  public disconnectSlackIntegration(id: string): Observable<any> {
    return this.httpClient.delete(
      `${environment.apiUrl}/disconnect-slack/${id}`,
    );
  }

  public disconnectSlack(id: string) {
    return this.httpClient.delete(
      `${environment.apiUrl}/disconnect-slack/${id}`,
    );
  }

  public getSlackIntegration(userId: string): Observable<IntegrationSlack> {
    this.loading$.next(true);
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    return this.httpClient.get<IntegrationSlack>(
      `${environment.apiUrl}/integrate-slacks/${userId}`,
      { headers: headers },
    );
  }

  public generateApiKey(userId: string): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${this.auth_token}`,
    });
    return this.httpClient.post<any>(
      `${environment.apiUrl}/auth/generate-api-key/${userId}`,
      {},
      { headers: headers },
    );
  }

  public getPlan(userId: string): Observable<Plan> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${this.auth_token}`,
    });
    return this.httpClient.get<Plan>(
      `${environment.apiUrl}/auth/users/plan/${userId}`,
      { headers: headers, context: withCache() },
    );
  }
  public getPlanWithName(name: string): Observable<Plan> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    return this.httpClient.get<Plan>(
      `${environment.apiUrl}/plans/name/${name}`,
      { headers: headers, context: withCache() },
    );
  }
  public getAllPlan(): Observable<Array<AllPlan>> {
    if (
      localStorage.getItem(ConstantService.localStorageKeys.access_Token) !==
      null
    ) {
      this.auth_token = localStorage.getItem(
        ConstantService.localStorageKeys.access_Token,
      );
      const headers = new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${this.auth_token}`,
      });
      return this.httpClient.get<Array<AllPlan>>(
        `${environment.apiUrl}/plans`,
        { headers: headers, context: withCache() },
      );
    } else {
      this.delay(500);
      this.getAllPlan();
    }
  }

  public getNotifications(): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    const userId = localStorage.getItem(
      ConstantService.localStorageKeys.userId,
    );

    return this.httpClient.get(
      `${ConstantService.apiRoute.diffAlerts}/user/${userId}`, {
        'headers': headers
      }
    );
  }

  public getDemoToken() {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${this.auth_token}`,
    });
    return this.httpClient.get(`${environment.apiUrl}/auth/tokendemo`, {
      responseType: 'text', 'headers': headers
    });
  }
  public postAlert(payload: object): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    console.log(payload);
    const req = this.httpClient.post(
      `${environment.apiUrl}/alerts`,
      JSON.stringify(payload),
      { headers },
    );

    return req;
  }

  public updateSlackIntegration(id, body): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    console.log('the data is', id, body);
    return this.httpClient.patch<any>(
      `${environment.apiUrl}/integrate-slack/${id}`,
      body,
      { headers },
    );
  }

  public updateRumSettings(userId, payload): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${this.auth_token}`,
    });
    return this.httpClient.put(
      `${ConstantService.apiRoute.updateRUMSettings}/${userId}`,
      payload,
      { headers },
    );
  }

  public getScaffolding(userId: string): Observable<Scaffolding> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    return this.httpClient.get<Scaffolding>(
      `${environment.apiUrl}/alerts/${userId}`,
      { headers: headers },
    );
  }

  public updateScaffolding(alertId: string, payload: object): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    const req = this.httpClient.patch(
      `${environment.apiUrl}/alerts/${alertId}`,
      JSON.stringify(payload),
      { headers: headers },
    );

    return req;
  }

  delay(ms: number) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }
  /* getLoading(): Observable<boolean> {
    return this.loading$;
  }*/
}
