import { Component, OnDestroy } from '@angular/core';
import { AuthService } from '../../../../services/core/auth/auth.service';
import { Subscription } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ActivatedRoute } from '@angular/router';

declare let google: any;
@Component({
    selector: 'app-google-login',
    templateUrl: './google-login.component.html',
    styleUrls: ['./google-login.component.scss'],
    standalone: false
})
export class GoogleLoginComponent implements OnDestroy {
  loading: boolean;
  returnNext;
  gmUid;
  private subscription: Subscription;

  constructor(
    private authService: AuthService,
    private activatedRoute: ActivatedRoute
  ) {}

  ngOnInit() {
    this.activatedRoute.queryParams.subscribe((result) => {
      
      this.gmUid = result.gmUid;
    });
    google.accounts.id.initialize({
      client_id: environment.googleClientId,
      callback: this.loginWithGoogle.bind(this),
      use_fedcm_for_prompt: true,
    });

    google.accounts.id.renderButton(document.getElementById('gsi_button'), {
      theme: 'outline',
      size: 'large',
    });
    this.returnNext =
      decodeURIComponent(
        window.location.href?.split('?')?.[1]?.split('=')?.[1] || ''
      ) || '';
    // console.log(this.route.paramMap.keys);
    // this.returnNext = this.route.paramMap.get('returnUrl');
  }

  ngOnDestroy() {
    if (this.subscription && !this.subscription.closed) {
      this.subscription.unsubscribe();
    }
  }

  async loginWithGoogle(response) {
    this.loading = true;
    if (this.gmUid) {
      this.authService.loginWithGoogle(response, this.gmUid);
    } else {
      await this.authService.loginWithGoogle(response);
    }
  }

  logOut(): void {
    this.authService.logoutUser();
  }
}
