import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import {
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { AuthService } from 'src/app/services/core/auth/auth.service';
import { ConstantService } from 'src/app/shared/constant/constant.service';
import { HelperService } from 'src/app/shared/helper/helper.service';
@Component({
    selector: 'app-login-form',
    templateUrl: './login-form.component.html',
    styleUrls: ['./login-form.component.scss'],
    standalone: false
})
export class LoginFormComponent implements OnInit {
  public form: UntypedFormGroup;
  public flatlogicEmail = '';
  public flatlogicPassword = '';

  constructor(
    private authService: AuthService,
    private helperService: HelperService
  ) {}

  public ngOnInit(): void {
    this.form = new UntypedFormGroup({
      email: new UntypedFormControl(this.flatlogicEmail, [Validators.required]),
      password: new UntypedFormControl(this.flatlogicPassword, [
        Validators.required,
      ]),
    });
  }

  public async login() {
    try {
      if (this.form.invalid) return
      const response = await this.authService
        .loginUser({
          username: this.form.value.email,
          password: this.form.value.password,
        })
        .toPromise();
      localStorage.setItem(
        ConstantService.localStorageKeys.access_Token,
        response.accessToken
      );
      localStorage.setItem(
        ConstantService.localStorageKeys.refresh_Token,
        response.refreshToken
      );

      localStorage.setItem(
        ConstantService.localStorageKeys.userId,
        response.userId
      );
      window.location.href = window.location.origin + '/home';
    } catch (error) {
      this.helperService.createSnackBar(error.error.error.message);
    }
  }
}
