import { Component,Input, OnInit  } from '@angular/core';

@Component({
    selector: 'app-oops-no-data',
    templateUrl: './oops-no-data.component.html',
    styleUrl: './oops-no-data.component.scss',
    standalone: false
})
export class OopsNoDataComponent implements OnInit {

  @Input() message:string ;

  ngOnInit(): void {

  }

}
