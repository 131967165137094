const data = {
  "AFG": 123.71,
  "AFRICA": 541.25,
  "ALB": 24.42,
  "DZA": 633.65,
  "ASM": 647.06,
  "AGO": 167.22,
  "ATG": 611.11,
  "ARG": 353.96,
  "ARM": 262.43,
  "ABW": 550,
  "ASEAN": 567.48,
  "ASIA": 590.48,
  "AUS": 556.3,
  "AUT": 101.71,
  "AZE": 669.99,
  "BHS": 653.66,
  "BHR": 902.41,
  "BGD": 683.12,
  "BRB": 600,
  "BLR": 314.91,
  "BEL": 117.74,
  "BLZ": 155.56,
  "BEN": 590,
  "BTN": 24.19,
  "BOL": 489.11,
  "BIH": 623.4,
  "BWA": 849.42,
  "BRA": 96.4,
  "BRN": 892.67,
  "BGR": 264.71,
  "BFA": 554.91,
  "BDI": 230.77,
  "CPV": 480,
  "KHM": 470.59,
  "CMR": 285.71,
  "CAN": 165.15,
  "CYM": 642.86,
  "CAF": 0,
  "TCD": 615.39,
  "CHL": 272.1,
  "CHN": 583.61,
  "COL": 268.97,
  "COM": 642.86,
  "COG": 713.73,
  "COD": 27.04,
  "COK": 250,
  "CRI": 24.77,
  "CIV": 393.53,
  "HRV": 173.21,
  "CUB": 638.98,
  "CYP": 505.32,
  "CZE": 413.8,
  "DNK": 144.15,
  "DJI": 450,
  "DMA": 600,
  "DOM": 579.4,
  "ECU": 176.28,
  "EGY": 574.04,
  "SLV": 118.46,
  "GNQ": 605.1,
  "ERI": 590.91,
  "EST": 343.85,
  "SWZ": 142.86,
  "ETH": 23.55,
  "EU": 213.39,
  "EUROPE": 281.75,
  "FLK": 1e3,
  "FRO": 354.17,
  "FJI": 278.26,
  "FIN": 72.48,
  "FRA": 44.13,
  "GUF": 204.08,
  "PYF": 436.62,
  "G20": 477.2,
  "G7": 340.32,
  "GAB": 429.47,
  "GMB": 666.67,
  "GEO": 168.06,
  "DEU": 343.96,
  "GHA": 452.86,
  "GRC": 319.6,
  "GRL": 111.11,
  "GRD": 666.67,
  "GLP": 493.9,
  "GUM": 611.11,
  "GTM": 272.66,
  "GIN": 182.72,
  "GNB": 625,
  "GUY": 634.33,
  "HTI": 534.65,
  "HND": 289.5,
  "HKG": 681.99,
  "HUN": 182.25,
  "ISL": 28.33,
  "IND": 713.01,
  "IDN": 682.43,
  "IRN": 641.73,
  "IRQ": 689.4,
  "IRL": 284.05,
  "ISR": 567.26,
  "ITA": 286.9,
  "JAM": 561.25,
  "JPN": 493.59,
  "JOR": 539.21,
  "KAZ": 821.9,
  "KEN": 96.95,
  "KIR": 500,
  "XKX": 919.36,
  "KWT": 636.91,
  "KGZ": 150.77,
  "LAO": 232.12,
  "LATIN AMERICA AND CARIBBEAN": 256.14,
  "LVA": 136.36,
  "LBN": 369.47,
  "LSO": 20.83,
  "LBR": 435.9,
  "LBY": 830.53,
  "LTU": 137.84,
  "LUX": 134.62,
  "MAC": 448.98,
  "MDG": 477.27,
  "MWI": 54.65,
  "MYS": 607.88,
  "MDV": 611.77,
  "MLI": 394.5,
  "MLT": 477.48,
  "MTQ": 516.78,
  "MRT": 481.71,
  "MUS": 633.03,
  "MEX": 492.34,
  "MIDDLE EAST": 641.43,
  "MDA": 632.89,
  "MNG": 785.08,
  "MNE": 422.22,
  "MSR": 1e3,
  "MAR": 616.82,
  "MOZ": 127.81,
  "MMR": 588.95,
  "NAM": 47.62,
  "NRU": 750,
  "NPL": 23.36,
  "NLD": 255.67,
  "NCL": 585.76,
  "NZL": 104.42,
  "NIC": 288.33,
  "NER": 687.5,
  "NGA": 508.82,
  "NORTH AMERICA": 343.02,
  "PRK": 344.26,
  "MKD": 524.62,
  "NOR": 30.32,
  "OCEANIA": 494.88,
  "OECD": 339.53,
  "OMN": 545.88,
  "PAK": 445.52,
  "PSE": 460.78,
  "PAN": 258.74,
  "PNG": 513.74,
  "PRY": 24.86,
  "PER": 287.81,
  "POL": 616.2,
  "PRT": 112.24,
  "PRI": 660.8,
  "QAT": 602.65,
  "REU": 525.22,
  "ROU": 246.02,
  "RUS": 447.87,
  "RWA": 301.89,
  "KNA": 636.36,
  "LCA": 650,
  "SPM": 600,
  "VCT": 600,
  "WSM": 400,
  "STP": 555.56,
  "SAU": 696.31,
  "SEN": 535.4,
  "SRB": 678.9,
  "SYC": 571.43,
  "SLE": 47.62,
  "SGP": 470.87,
  "SVK": 96.52,
  "SVN": 229.22,
  "SLB": 636.36,
  "SOM": 523.81,
  "ZAF": 709.69,
  "KOR": 432.11,
  "SSD": 610.17,
  "ESP": 146.26,
  "LKA": 509.78,
  "SDN": 214.33,
  "SUR": 383.18,
  "SWE": 35.92,
  "CHE": 29.44,
  "SYR": 682.27,
  "TWN": 644.36,
  "TJK": 87.5,
  "TZA": 371.59,
  "THA": 549.85,
  "PHL": 601.15,
  "TGO": 478.26,
  "TON": 571.43,
  "TTO": 682.11,
  "TUN": 560.36,
  "TUR": 441.76,
  "TKM": 1306.3,
  "TCA": 653.85,
  "UGA": 57.39,
  "UKR": 162.13,
  "ARE": 492.7,
  "GBR": 209.81,
  "USA": 369.53,
  "URY": 115.68,
  "UZB": 1121.18,
  "VUT": 500,
  "VEN": 180.25,
  "VNM": 472.47,
  "VGB": 647.06,
  "VIR": 641.79,
  "WORLD": 480.69,
  "YEM": 586.32,
  "ZMB": 111,
  "ZWE": 298.44
};
const type = "average";
var average_intensities_min_default = {
  data,
  type
};
export { data, average_intensities_min_default as default, type };