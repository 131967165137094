<div *ngIf="!errorMsg" class="graph m-5 p-4">
  <div *ngIf="!workflows || workflows.length === 0" class="text-center my-5 py-5">
    <mat-icon class="text-muted" style="font-size: 48px; height: 48px; width: 48px;">route</mat-icon>
    <p class="mt-3 text-muted">You haven't created any User Journeys yet.</p>
    <button class="btn btn-outline-primary mt-2" (click)="navigateToMyAccount()">
      Create your first journey
    </button>
  </div>

  <mat-form-field appearance="outline" class="mb-3" *ngIf="workflows.length > 1">
    <mat-label>Select a RUM Workflow</mat-label>
    <mat-select [(ngModel)]="selectedWorkflow" (selectionChange)="selectWorkflow($event.value)">
      <mat-option *ngFor="let wf of workflows" [value]="wf">
        {{ wf.name | titlecase }}
      </mat-option>
    </mat-select>
  </mat-form-field>

  <div *ngIf="selectedWorkflow" class="d-flex justify-content-center align-items-center mb-3">
    <h2>{{ selectedWorkflow.name | titlecase }} - User Journey</h2>
  </div>

  <div class="device-query-switch d-flex justify-content-between align-items-center" *ngIf="workflows && workflows.length > 0">
    <div class="radio-inputs">
      <label class="radio">
        <input
          type="radio"
          name="radioDevice"
          value="desktop"
          [(ngModel)]="selectedDevice"
          (ngModelChange)="onFilterChange($event)"
        />
        <span class="name">Desktop</span>
      </label>
      <label class="radio">
        <input
          type="radio"
          name="radioDevice"
          value="mobile"
          [(ngModel)]="selectedDevice"
          (ngModelChange)="onFilterChange($event)"
        />
        <span class="name">Mobile</span>
      </label>
    </div>
    <div class="radio-inputs">
      <label class="radio">
        <input
          type="radio"
          name="radioInterval"
          value="daily"
          [(ngModel)]="selectedQueryInterval"
          (ngModelChange)="onFilterChange($event)"
        />
        <span class="name">Daily</span>
      </label>
      <label class="radio">
        <input
          type="radio"
          name="radioInterval"
          value="weekly"
          [(ngModel)]="selectedQueryInterval"
          (ngModelChange)="onFilterChange($event)"
        />
        <span class="name">Weekly</span>
      </label>
      <label class="radio">
        <input
          type="radio"
          name="radioInterval"
          value="monthly"
          [(ngModel)]="selectedQueryInterval"
          (ngModelChange)="onFilterChange($event)"
        />
        <span class="name">Monthly</span>
      </label>
    </div>
  </div>

  <div *ngIf="loading" class="text-muted">Loading RUM data...</div>

  <div class="chart-header mt-3" *ngIf="chartOptions && !loading">
    <mat-icon class="reading-arrow">arrow_downward</mat-icon>
    <span>Reading Direction</span>
  </div>

  <div *ngIf="!loading" id="chartdiv" style="width: 100%; height: 300px;" class="mt-2"></div>

  <div *ngIf="chartErrorMsg && !loading" class="alert alert-danger mt-3">
    {{ chartErrorMsg }}
  </div>
</div>
