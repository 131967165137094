

<!-- Contenu principal affiché uniquement s’il n’y a pas d’erreur globale -->
<div *ngIf="!errorMsg" class="graph m-5 p-4">
  <!-- Display no workflows message with create button -->
  <div *ngIf="!workflows || workflows.length === 0" class="text-center my-5 py-5">
    <mat-icon class="text-muted" style="font-size: 48px; height: 48px; width: 48px;">route</mat-icon>
    <p class="mt-3 text-muted">You haven't created any User Journeys yet.</p>
    <button class="btn btn-outline-primary mt-2" (click)="navigateToMyAccount()">
      Create your first journey
    </button>
  </div>

  <!-- Display selected workflow details -->
  <div *ngIf="selectedWorkflow" class="d-flex justify-content-center align-items-center">
    <h2>{{ selectedWorkflow.name | titlecase }} - User Journey</h2>
  </div>

  <div *ngIf="workflows && workflows.length > 0" class="d-flex justify-content-between align-items-center">
    <!-- Workflow selection using MatSelect -->
    <mat-form-field appearance="outline" *ngIf="workflows.length > 1">
      <mat-label>Select a CRUX Workflow</mat-label>
      <mat-select [(ngModel)]="selectedWorkflow" (selectionChange)="selectWorkflow($event.value)">
        <mat-option *ngFor="let wf of workflows" [value]="wf">
          {{ wf.name | titlecase }}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <div class="radio-inputs">
      <label class="radio">
        <input
          type="radio"
          [name]="'radio'"
          value="mobile"
          [(ngModel)]="device"
          (change)="setDeviceUrl($event)"
        />
        <span class="name">Mobile</span>
      </label>
      <label class="radio">
        <input
          type="radio"
          [name]="'radio'"
          value="desktop"
          [(ngModel)]="device"
          (change)="setDeviceUrl($event)"
        />
        <span class="name">Desktop</span>
      </label>
    </div>
  </div>

  <!-- Loader -->
  <div *ngIf="loading">Loading CRUX data...</div>

  <!-- Chart header -->
  <div class="chart-header" *ngIf="chartOptions && !loading">
    <mat-icon class="reading-arrow">arrow_downward</mat-icon>
    <span>Reading Direction</span>
  </div>

  <!-- Chart display -->
  <div *ngIf="!loading" id="chartdiv" class="my-5" style="width: 100%; height: 300px;"></div>

  <!-- Message d'erreur spécifique au chart (URLs invalides, etc.) -->
  <div *ngIf="chartErrorMsg" class="alert alert-danger mb-3">
    {{ chartErrorMsg }}
  </div>
</div>
