import { Injectable, signal, computed } from '@angular/core';
import { UserService } from './user.service';
import { Router } from '@angular/router';
// Define interfaces in the same file as the service
interface RumSettings {
  samplingRate: number;
}

interface UserData {
  id: string;
  userId: string;
  name: string;
  email: string;
  planId: string;
  apiKey: string;
  roles: string[];
  rumSettings: RumSettings;
  createdAt: string | null;
  lastLogin: string;
  lastPaymentDate: string;
  paymentCycle: string;
  isTrialActive: boolean | null;
  subStatus: string;
  trialEndDate: string | null;
  trialStartDate: string | null;
}

export interface User {
  user: UserData;
}

export interface UserState {
  user: User | null;
  isLoading: boolean;
  error: any;
}

@Injectable({
  providedIn: 'root'
})
export class UserStateService {
  private readonly state = signal<UserState>({
    user: null,
    isLoading: false,
    error: null
  });

  // Exposed signals for components to consume
  readonly user = computed(() => this.state().user);
  readonly isLoading = computed(() => this.state().isLoading);
  readonly error = computed(() => this.state().error);

  constructor(
    private userService: UserService,
    private router: Router
  ) {}

  async loadUser(userId: string) {
    // Return early if user is already loaded
    if (this.state().user) {
      return this.state().user;
    }

    try {
      this.state.update(state => ({ ...state, isLoading: true }));
      const user = await this.userService.getCurrentUser(userId).toPromise();
      this.state.update(state => ({ 
        ...state, 
        user,
        isLoading: false 
      }));
      return user;
    } catch (error: any) {
      this.state.update(state => ({ 
        ...state, 
        error,
        isLoading: false 
      }));
      
      // Handle 401 Unauthorized error
      if (error.status === 401) {
        localStorage.clear(); // Clear all stored data
        this.clearUser();
        await this.router.navigate(['/login']);
      }
      
      throw error;
    }
  }

  clearUser() {
    this.state.update(state => ({ 
      ...state, 
      user: null,
      error: null 
    }));
  }
} 