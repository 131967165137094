import { CommonModule } from '@angular/common';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { NavigationWorkflowService } from 'src/app/services/navigation-workflow.service';
import { MatSelectModule } from '@angular/material/select';
import { FormsModule } from '@angular/forms';
import { forkJoin, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { MatIconModule } from '@angular/material/icon';
import { WebsiteAnalyzeService } from 'src/app/services/common/website-analyze/website-analyze.service';
import { MatRadioModule } from '@angular/material/radio';
import { MatDialog } from '@angular/material/dialog';
import { ShowDetailsWorkflowComponent } from '../show-details-workflow/show-details-workflow.component';
// --- amCharts ---
import * as am5 from "@amcharts/amcharts5";
import * as am5percent from "@amcharts/amcharts5/percent";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import { ActivatedRoute, Router } from '@angular/router';

@Component({
    selector: 'app-rum-workflow',
    standalone : true,
    imports: [CommonModule, MatSelectModule, FormsModule, MatIconModule, MatRadioModule],
    templateUrl: './rum-workflow.component.html',
    styleUrls: ['./rum-workflow.component.scss']
})
export class RumWorkflowComponent implements OnInit, OnDestroy {
  userId: string;
  projectId: string;
  workflows: any[] = [];
  selectedWorkflow: any;
  rumData: any[] = [];
  validRumData: any[] = [];

  loading = false;
  errorMsg = '';
  chartErrorMsg = '';

  selectedDevice: string = 'desktop';
  selectedQueryInterval: string = 'daily'; // daily, weekly, monthly


  // amCharts root
  private root!: am5.Root;

  constructor(
    private workflowService: NavigationWorkflowService,
    private websiteAnalyzeService: WebsiteAnalyzeService,
    private dialog: MatDialog,
    private route: ActivatedRoute,
    private router: Router,
  ) {}

  ngOnInit(): void {
    this.userId = localStorage.getItem('userId') || '';
    
    // Get the project ID from the parent route
    this.route.parent?.params.subscribe(params => {
      this.projectId = params['id'];
      if (this.projectId) {
        this.loadRumWorkflows();
      } else {
        // If parent params don't have the ID, try to extract it from the URL
        const urlParts = window.location.pathname.split('/');
        const rumIndex = urlParts.indexOf('rum');
        if (rumIndex !== -1 && urlParts.length > rumIndex + 1) {
          this.projectId = urlParts[rumIndex + 1];
          this.loadRumWorkflows();
        } else {
          this.errorMsg = 'No project ID found in the URL.';
        }
      }
    });
  }

  loadRumWorkflows(): void {
    if (!this.userId) {
      this.errorMsg = 'No userId found in localStorage.';
      return;
    }
    this.loading = true;

    this.workflowService.getNavigationWorkflows(this.projectId).subscribe({
      next: (wfs) => {
        this.workflows = wfs.filter(wf => wf.type === 'RUM');
        if (this.workflows.length === 0) {
          this.errorMsg = 'No RUM workflows found.';
        } else {
          this.selectedWorkflow = this.workflows[0];
          this.loadRumDataForWorkflow(this.selectedWorkflow);
        }
        this.loading = false;
      },
      error: (err) => {
        console.error(err);
        this.errorMsg = 'Error loading RUM workflows.';
        this.loading = false;
      },
    });
  }

  selectWorkflow(wf: any): void {
    this.selectedWorkflow = wf;
    this.loadRumDataForWorkflow(wf);
  }

  loadRumDataForWorkflow(workflow: any): void {
    if (!workflow || !workflow.pages) return;
    const pages = workflow.pages;

    const observables = pages.map((p: { url: string }) =>
      this.websiteAnalyzeService.getRumDocWithUxr(this.userId, p.url, this.selectedDevice, this.selectedQueryInterval).pipe(
        catchError(err => {
          console.error('Error retrieving RUM doc for url=', p.url, err);
          return of({ url: p.url, error: 'Error retrieving RUM doc' });
        }),
        map((doc: any) => {
          if (doc && doc.uxr !== undefined) {
            return { url: p.url, doc };
          } else {
            return { url: p.url, error: 'No RUM data found' };
          }
        })
      )
    );

    forkJoin(observables).subscribe((results: any[]) => {
      this.rumData = results;
      this.validRumData = this.rumData.filter(item => !item.error);

      const invalidUrls = this.rumData.filter(item => item.error).map(item => item.url);
      if (invalidUrls.length > 0) {
        this.chartErrorMsg = 'The following URLs have no RUM data: ' + invalidUrls.join(', ');
      } else {
        this.chartErrorMsg = '';
      }

      this.buildAmChartFunnel();
      this.loading = false;
    });
  }

  buildAmChartFunnel(): void {
    if (this.root) {
      this.root.dispose();
    }

    // Créer la racine
    this.root = am5.Root.new("chartdiv");
    this.root.setThemes([am5themes_Animated.new(this.root)]);

    // Créer le SlicedChart
    const chart = this.root.container.children.push(
      am5percent.SlicedChart.new(this.root, {
        layout: this.root.verticalLayout
      })
    );

    // Créer la série Funnel
    const series = chart.series.push(
      am5percent.FunnelSeries.new(this.root, {
        valueField: "value",
        categoryField: "category",
        orientation: "horizontal",
        bottomRatio: 1,
        alignLabels: false
      })
    );

    // Préparer les données
    const chartData = this.validRumData.map(item => ({
      category: item.url,
      value: item.doc?.uxr || 0
    }));
    series.data.setAll(chartData);

    // Rendre les slices interactives
    series.slices.template.setAll({
      interactive: true,
      cursorOverStyle: "pointer"
    });

    // 8. Écouter l'événement "click" sur les slices
    series.events.on("datavalidated", () => {
      series.dataItems.forEach((dataItem) => {
        const slice = dataItem.get("slice");
        if (slice) {
          slice.setAll({
            interactive: true,
            cursorOverStyle: "pointer"
          });
          slice.events.on("click", () => {
            const dataContext = dataItem.dataContext as { category: string; value: number };
            this.openModal(dataContext.category);
          });
        }
      });
    });


    // Label "UXR : {value}"
    series.labels.template.setAll({
      text: "UXR : {value}%",
      interactive: false,
      rotation: 0,
      textAlign: "start"
    });

    // Légende
    const legend = chart.children.push(
      am5.Legend.new(this.root, {
        centerX: am5.p50,
        x: am5.p50,
        marginTop: 15,
        marginBottom: 15
      })
    );
    legend.labels.template.set("text", "{category}");
    legend.valueLabels.template.setAll({ forceHidden: true });
    legend.data.setAll(series.dataItems);

    // Animation
    series.appear();
    chart.appear(1000, 100);
  }

  onFilterChange(): void {
    if (this.selectedWorkflow) {
      this.loadRumDataForWorkflow(this.selectedWorkflow);
    }
  }
  openModal(url: string): void {
    const found = this.rumData.find(d => d.url === url);
    if (!found || !found.doc || !found.doc.metricData) {
      return;
    }


    const metricDataArray = found.doc.metricData
    const calculatedMetrics = metricDataArray[0].calculated;


    const lcpObj = calculatedMetrics.find((m: any) => m._id === 'LCP');
    const clsObj = calculatedMetrics.find((m: any) => m._id === 'CLS');
    const inpObj = calculatedMetrics.find((m: any) => m._id === 'INP');



    const lcpData = lcpObj ? {
      good: (lcpObj.pctGood * 100).toFixed(2),
      need: (lcpObj.pctNeedsImprovement * 100).toFixed(2),
      poor: (lcpObj.pctPoor * 100).toFixed(2),
      p75: lcpObj.percentiles[1].toFixed(2)
    } : null;

    const clsData = clsObj ? {
      good: (clsObj.pctGood * 100).toFixed(2),
      need: (clsObj.pctNeedsImprovement * 100).toFixed(2),
      poor: (clsObj.pctPoor * 100).toFixed(2),

      p75: (clsObj.percentiles[1]).toFixed(2)
    } : null;

    const inpData = inpObj ? {
      good: (inpObj.pctGood * 100).toFixed(2),
      need: (inpObj.pctNeedsImprovement * 100).toFixed(2),
      poor: (inpObj.pctPoor * 100).toFixed(2),
      p75: inpObj.percentiles[1].toFixed(2)
    } : null;

    const modalData = {
      url,
      device: found.doc.device,
      lcpData,
      clsData,
      inpData,

    };

    this.dialog.open(ShowDetailsWorkflowComponent, {
      width: '1000px',
      height: '500px',
      data: modalData
    });
  }

  ngOnDestroy(): void {
    if (this.root) {
      this.root.dispose();
    }
  }

  navigateToMyAccount() {
    this.router.navigate(['/home/myAccount'], { queryParams: { tab: 3 } });
  }
}
