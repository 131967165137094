import { Component, Input } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { UserHelperService } from '../../services/common/user-helper.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { CommonModule } from '@angular/common';

@Component({
    selector: 'app-help-widget',
    templateUrl: './help-widget.component.html',
    styleUrls: ['./help-widget.component.scss'],
    standalone: false
})
export class HelpWidgetComponent {
  @Input() currentURL: string;
  @Input() userId: string;
  @Input() projectID: string;
  @Input() Url: string;

  showWidget = false;
  helpForm: FormGroup;
  screenshotFile: File | null = null;

  constructor(
    private fb: FormBuilder,
    private userHelperService: UserHelperService,
    private snackBar: MatSnackBar
  ) {
    this.helpForm = this.fb.group({
      description: ['', Validators.required],
      userStory: [''],
    });
  }

  toggleWidget() {
    this.showWidget = !this.showWidget;
  }

  onFileSelected(event: any) {
    this.screenshotFile = event.target.files[0];
  }

  onSubmit() {
    if (this.screenshotFile) {
      const reader = new FileReader();
      reader.onload = () => {
        const base64String = (reader.result as string).split(',')[1];
        const payload = {
          description: this.helpForm.value.description,
          nbUserStory: this.helpForm.value.userStory,
          Url: this.Url,
          OriginDomain: this.currentURL,
          userID: this.userId,
          projectID: this.projectID,
          screenshot: base64String,
        };
        this.sendHelpRequest(payload);
      };
      reader.readAsDataURL(this.screenshotFile);
    } else {
      const payload = {
        description: this.helpForm.value.description,
        nbUserStory: this.helpForm.value.userStory,
        Url: this.Url,
        OriginDomain: this.currentURL,
        userID: this.userId,
        projectID: this.projectID,
      };
      this.sendHelpRequest(payload);
    }
  }

  private sendHelpRequest(payload: any) {
    this.userHelperService.sendHelpRequest(payload).subscribe({
      next: () => {
        this.snackBar.open('Request sent successfully!', 'Close', {
          duration: 3000,
        });
        this.helpForm.reset();
        this.screenshotFile = null;
        this.toggleWidget();
      },
      error: () =>  {
        this.snackBar.open('Error sending request. Please try again.', 'Close', {
        duration: 3000,
      });
      }

    });
  }
}
