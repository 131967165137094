import { Component, OnInit } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { Router } from '@angular/router';
import { CompilerService } from 'src/app/shared/compiler/compiler.service';
import { ConstantService } from 'src/app/shared/constant/constant.service';
import { HelperService } from 'src/app/shared/helper/helper.service';
import { RegisterUserResponse } from 'src/app/shared/models/user.models';
import { AuthService } from '../../../../services/core/auth/auth.service';

@Component({
    selector: 'app-register',
    templateUrl: './register.component.html',
    styleUrls: ['./register.component.scss'],
    standalone: false
})
export class RegisterComponent implements OnInit {
  loading: boolean = false;
  registerUserFormGroup: UntypedFormGroup;
  constructor(
    private formBuilder: UntypedFormBuilder,
    private compilerService: CompilerService,
    private authService: AuthService,
    private helperService: HelperService,

    private router: Router
  ) {}

  ngOnInit() {
    this.registerUserFormGroup = this.formBuilder.group(
      {
        firstname: ['', [Validators.required]],
        lastname: ['', [Validators.required]],
        email: ['', [Validators.required]],
        password: [
          '',
          [
            Validators.required,
            Validators.minLength(8),
            Validators.maxLength(30),
          ],
        ],
        confirmPassword: [
          '',
          [
            Validators.required,
            Validators.minLength(8),
            Validators.maxLength(30),
          ],
        ],
      },
      { validators: this.checkPasswords }
    );
  }
  /**
   * This to check if the password are same or not
   * @param group
   * @returns
   */
  checkPasswords(group: UntypedFormGroup) {
    const password = group.controls.password.value;
    const confirmpassword = group.controls.confirmPassword.value;
    return password == confirmpassword
      ? null
      : group.controls.confirmPassword.setErrors({ notSame: true });
  }
  /**
   * This to register User
   */
  async registerUser() {
    try {
      if (!this.registerUserFormGroup.invalid) {
        this.loading = true;
        const { firstname, lastname, email, password } =
          this.registerUserFormGroup.value;
        await this.authService
          .registerUser({
            firstName: firstname,
            lastName: lastname,
            email,
            username: email,
            password,
          })
          .toPromise();

        this.helperService.createSnackBar(
          ConstantService.successMessage.createAccount
        );
        this.loading = false;
        this.router.navigate(['/auth/login']);
      }
    } catch (error) {
      this.loading = false;
      this.helperService.createSnackBar(error.error.error.message);
    }
  }

  navigateToHome() {
    this.router.navigate(['/']);
  }
}
